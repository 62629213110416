var Handlebars = require("../../../../CSR_QelpCare_Projects/csr-claro-br/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <li class=\"card device "
    + alias4(((helper = (helper = lookupProperty(helpers,"manufacturer") || (depth0 != null ? lookupProperty(depth0,"manufacturer") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"manufacturer","hash":{},"data":data,"loc":{"start":{"line":6,"column":29},"end":{"line":6,"column":45}}}) : helper)))
    + " "
    + alias4(((helper = (helper = lookupProperty(helpers,"path") || (depth0 != null ? lookupProperty(depth0,"path") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"path","hash":{},"data":data,"loc":{"start":{"line":6,"column":46},"end":{"line":6,"column":54}}}) : helper)))
    + "\">\n        <a\n          class=\"wrapper\"\n          href=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"root") || (depth0 != null ? lookupProperty(depth0,"root") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"root","hash":{},"data":data,"loc":{"start":{"line":9,"column":16},"end":{"line":9,"column":24}}}) : helper)))
    + alias4((lookupProperty(helpers,"route")||(depth0 && lookupProperty(depth0,"route"))||alias2).call(alias1,"manufacturers",{"name":"route","hash":{},"data":data,"loc":{"start":{"line":9,"column":24},"end":{"line":9,"column":49}}}))
    + "/"
    + alias4(((helper = (helper = lookupProperty(helpers,"manufacturer") || (depth0 != null ? lookupProperty(depth0,"manufacturer") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"manufacturer","hash":{},"data":data,"loc":{"start":{"line":9,"column":50},"end":{"line":9,"column":66}}}) : helper)))
    + "/"
    + alias4(((helper = (helper = lookupProperty(helpers,"path") || (depth0 != null ? lookupProperty(depth0,"path") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"path","hash":{},"data":data,"loc":{"start":{"line":9,"column":67},"end":{"line":9,"column":75}}}) : helper)))
    + "\"\n          data-navigate-to=\""
    + alias4((lookupProperty(helpers,"route")||(depth0 && lookupProperty(depth0,"route"))||alias2).call(alias1,"manufacturers",{"name":"route","hash":{},"data":data,"loc":{"start":{"line":10,"column":28},"end":{"line":10,"column":53}}}))
    + "/"
    + alias4(((helper = (helper = lookupProperty(helpers,"manufacturer") || (depth0 != null ? lookupProperty(depth0,"manufacturer") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"manufacturer","hash":{},"data":data,"loc":{"start":{"line":10,"column":54},"end":{"line":10,"column":70}}}) : helper)))
    + "/"
    + alias4(((helper = (helper = lookupProperty(helpers,"path") || (depth0 != null ? lookupProperty(depth0,"path") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"path","hash":{},"data":data,"loc":{"start":{"line":10,"column":71},"end":{"line":10,"column":79}}}) : helper)))
    + "\"\n        >\n          <div class=\"image\">\n            <img src=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"coverImageUrl") || (depth0 != null ? lookupProperty(depth0,"coverImageUrl") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"coverImageUrl","hash":{},"data":data,"loc":{"start":{"line":13,"column":22},"end":{"line":13,"column":39}}}) : helper)))
    + "\" alt=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":13,"column":46},"end":{"line":13,"column":54}}}) : helper)))
    + "\" />\n          </div>\n          <div class=\"details\">\n            <span class=\"brand\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"manufacturerName") || (depth0 != null ? lookupProperty(depth0,"manufacturerName") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"manufacturerName","hash":{},"data":data,"loc":{"start":{"line":16,"column":32},"end":{"line":16,"column":52}}}) : helper)))
    + "</span>\n            <span class=\"type\">"
    + alias4(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"type") : depth0)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "</span>\n            <h3 class=\"title\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":18,"column":30},"end":{"line":18,"column":38}}}) : helper)))
    + "</h3>\n          </div>\n        </a>\n      </li>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<!-- Most Viewed Devices Template -->\n<div class=\"widget most_viewed_devices\">\n  <h2 class=\"title\">"
    + container.escapeExpression((lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||container.hooks.helperMissing).call(alias1,"widget/most_viewed_devices/title",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":3,"column":20},"end":{"line":3,"column":68}}}))
    + "</h2>\n  <ul class=\"panel\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"content") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":4},"end":{"line":22,"column":13}}})) != null ? stack1 : "")
    + "  </ul>\n  <div class=\"pagination\" data-per-page=\"1\">\n    <div class=\"pagination-left\">\n      <i class=\"material-icons\">chevron_left</i>\n    </div>\n    <div class=\"pagination-right\">\n      <i class=\"material-icons\">chevron_right</i>\n    </div>\n  </div>\n</div>\n<!-- End Most Viewed Devices Template -->";
},"useData":true});
var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"accordion-item\">\n            <div class=\"title item-title\">\n                <div class=\"icon-wrapper\">\n                <i class=\"qc-icon "
    + alias4(((helper = (helper = lookupProperty(helpers,"path") || (depth0 != null ? lookupProperty(depth0,"path") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"path","hash":{},"data":data,"loc":{"start":{"line":8,"column":34},"end":{"line":8,"column":42}}}) : helper)))
    + "\" style=\"-webkit-mask-image: url("
    + alias4(((helper = (helper = lookupProperty(helpers,"iconImageUrl") || (depth0 != null ? lookupProperty(depth0,"iconImageUrl") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"iconImageUrl","hash":{},"data":data,"loc":{"start":{"line":8,"column":75},"end":{"line":8,"column":91}}}) : helper)))
    + ");\"></i>\n                </div>\n                <span class=\"category-name\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":10,"column":44},"end":{"line":10,"column":52}}}) : helper)))
    + " </span>\n                <i class=\"material-icons accordion-expand\">expand_more</i>\n            </div>\n            <div class=\"content\">\n                <ul class=\"panel\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"topics") : depth0),{"name":"each","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":20},"end":{"line":24,"column":29}}})) != null ? stack1 : "")
    + "                </ul>\n            </div>\n        </div>\n";
},"2":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, alias5=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <li class=\"card\">\n                        <a href=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"root") || (depth0 != null ? lookupProperty(depth0,"root") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"root","hash":{},"data":data,"loc":{"start":{"line":17,"column":33},"end":{"line":17,"column":41}}}) : helper)))
    + alias4((lookupProperty(helpers,"route")||(depth0 && lookupProperty(depth0,"route"))||alias2).call(alias1,"oss",{"name":"route","hash":{},"data":data,"loc":{"start":{"line":17,"column":41},"end":{"line":17,"column":56}}}))
    + "/"
    + alias4(alias5(((stack1 = (depths[2] != null ? lookupProperty(depths[2],"app") : depths[2])) != null ? lookupProperty(stack1,"path") : stack1), depth0))
    + "/"
    + alias4(((helper = (helper = lookupProperty(helpers,"topicPath") || (depth0 != null ? lookupProperty(depth0,"topicPath") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"topicPath","hash":{},"data":data,"loc":{"start":{"line":17,"column":76},"end":{"line":17,"column":89}}}) : helper)))
    + "\"\n                            class=\"wrapper navigate-to\"\n                            data-navigate-to=\""
    + alias4((lookupProperty(helpers,"route")||(depth0 && lookupProperty(depth0,"route"))||alias2).call(alias1,"oss",{"name":"route","hash":{},"data":data,"loc":{"start":{"line":19,"column":46},"end":{"line":19,"column":61}}}))
    + "/"
    + alias4(alias5(((stack1 = (depths[2] != null ? lookupProperty(depths[2],"app") : depths[2])) != null ? lookupProperty(stack1,"path") : stack1), depth0))
    + "/"
    + alias4(((helper = (helper = lookupProperty(helpers,"topicPath") || (depth0 != null ? lookupProperty(depth0,"topicPath") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"topicPath","hash":{},"data":data,"loc":{"start":{"line":19,"column":81},"end":{"line":19,"column":94}}}) : helper)))
    + "\">\n                            <span class=\"tutorial-title\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"topicName") || (depth0 != null ? lookupProperty(depth0,"topicName") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"topicName","hash":{},"data":data,"loc":{"start":{"line":20,"column":57},"end":{"line":20,"column":70}}}) : helper)))
    + "</span>\n                            <span class=\"see-usecase\">"
    + alias4((lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"widget/categories_tutorials_app/see-usecase",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":21,"column":54},"end":{"line":21,"column":113}}}))
    + "</span>\n                        </a>\n                    </li>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.escapeExpression, alias2=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"widget categories-tutorials categories-tutorials-app "
    + alias1(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"os") : depth0)) != null ? lookupProperty(stack1,"path") : stack1), depth0))
    + "\">\n    <h2 class=\"title\">"
    + alias1((lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||container.hooks.helperMissing).call(alias2,"widget/categories_tutorials_app/title",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":2,"column":22},"end":{"line":2,"column":75}}}))
    + "</h2>\n    <div class=\"accordion\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias2,(depth0 != null ? lookupProperty(depth0,"categories") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":8},"end":{"line":28,"column":17}}})) != null ? stack1 : "")
    + "    </div>\n</div>";
},"useData":true,"useDepths":true});
var Handlebars = require("../../../../CSR_QelpCare_Projects/csr-claro-br/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          <li class=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"widget") || (depth0 != null ? lookupProperty(depth0,"widget") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"widget","hash":{},"data":data,"loc":{"start":{"line":12,"column":21},"end":{"line":12,"column":31}}}) : helper)))
    + "\"><a\n              href=\"#"
    + alias4(((helper = (helper = lookupProperty(helpers,"widget") || (depth0 != null ? lookupProperty(depth0,"widget") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"widget","hash":{},"data":data,"loc":{"start":{"line":13,"column":21},"end":{"line":13,"column":31}}}) : helper)))
    + "\"\n              data-widget=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"widget") || (depth0 != null ? lookupProperty(depth0,"widget") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"widget","hash":{},"data":data,"loc":{"start":{"line":14,"column":27},"end":{"line":14,"column":37}}}) : helper)))
    + "\"\n            >"
    + alias4((lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,(lookupProperty(helpers,"concat")||(depth0 && lookupProperty(depth0,"concat"))||alias2).call(alias1,"widget/",(lookupProperty(helpers,"concat")||(depth0 && lookupProperty(depth0,"concat"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"widget") : depth0),"/title",{"name":"concat","hash":{},"data":data,"loc":{"start":{"line":15,"column":43},"end":{"line":15,"column":67}}}),{"name":"concat","hash":{},"data":data,"loc":{"start":{"line":15,"column":25},"end":{"line":15,"column":68}}}),{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":15,"column":13},"end":{"line":15,"column":70}}}))
    + "</a></li>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<!-- Side Index Template -->\n<div class=\"widget side-index\">\n  <div class=\"fixed-menu accordion-item\">\n    <h1 class=\"title item-title\">"
    + container.escapeExpression((lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||container.hooks.helperMissing).call(alias1,"widget/side_index/title",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":4,"column":33},"end":{"line":4,"column":72}}}))
    + "\n      <span class=\"material-icons\">\n        expand_more\n      </span>\n    </h1>\n    <div class=\"content\">\n      <ul class=\"panel\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"list") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":8},"end":{"line":16,"column":17}}})) != null ? stack1 : "")
    + "      </ul>\n    </div>\n    <div class=\"device-emulator-btn\">\n      <h2>DEVICE EMULATOR</h2>\n      <span>Open application<i class=\"material-icons\">chevron_right</i></span>\n    </div>\n  </div>\n</div>\n<!-- End Side Index Template -->";
},"useData":true});
import { Widgets } from "./Widgets";

export class Tabs extends Widgets {
  constructor(selector) {
    super(selector);
    this.tabs = [];
  }
  init() {
    this.initTabs();
  }
  initTabs() {
    if (!this.element) {
      console.warn("Tabs initialized but no element has been provided");
      return;
    }
    const tabs_head = this.element.querySelectorAll(".tabs-head .item-tab");
    const tabs_content = this.element.querySelectorAll(
      ".tabs-content .content-tab"
    );
    if (tabs_head) {
      tabs_head.forEach((elem) => {
        elem.addEventListener("click", (e) => {
          this.showTabItem(e);
        });
      });
    } else {
      console.warn("Tabs initialized but no .tabs-head were found");
    }
    if (tabs_content) {
      tabs_content.forEach((elem) => {
        if (!elem.classList.contains("active")) {
          elem.parentElement.removeChild(elem);
        }
        this.tabs.push(elem);
      });
    } else {
      console.warn("Tabs initialized but no .tabs-content were found");
    }
    if (tabs_head.length == 1) {
      tabs_head[0].style.display = "none";
      if (this.element.querySelector(".tabs-title")?.style)
        this.element.querySelector(".tabs-title").style.display = "none";
    } else {
      const e = {
        currentTarget: tabs_head[0],
      };
      this.showTabItem(e);
    }
  }
  showTabItem(e) {
    let path = e.currentTarget.dataset.path;
    this.setTabActive(path);
    let event = new CustomEvent("TabWidgetSelected", {
      detail: e.currentTarget,
    });
    this.element.dispatchEvent(event);
  }
  setTabActive(path) {
    if (!this.element) {
      console.warn("Tabs initialized but no element has been provided");
      return;
    }
    this.element.querySelectorAll(".tabs-head .item-tab").forEach((elem) => {
      elem.classList.remove("active");
      if (elem.classList.contains(path)) {
        elem.classList.add("active");
      }
    });
    this.tabs.forEach((item) => {
      item.classList.remove("active");
      if (item.parentElement) item.parentElement.removeChild(item);
      if (item.classList.contains(path)) {
        item.classList.add("active");
        this.element.querySelector(".tabs-content").append(item);
      }
    });
  }
}

import Router from 'vanilla-router';
import axios from 'axios';
import prototypes from "./prototypes";
import { SideIndex } from './widgets/SideIndex';
import { SimpleNavigate } from './widgets/SimpleNavigate';
import { Widgets } from './widgets/Widgets';
import { DeviceList } from './widgets/DeviceList';
import { Accordion } from './widgets/Accordion';
import { DeviceImage } from './widgets/DeviceImage';
import { Specs } from './widgets/Specs';
import { StepsNavigation } from './widgets/StepsNavigation';
import { Qlickr } from './widgets/Qlickr';
import { Tabs } from './widgets/Tabs';
import { ItemByFilter } from './widgets/ItemByFilter';
import { CardFilter } from './widgets/CardFilter';
import { Choices } from './widgets/Choices';
import { SMSSender } from './widgets/SMSSender';
import { WhatsAppInterface } from './widgets/WhatsAppInterface';
import { SendMail } from './widgets/SendMail';
import { CopyURI } from './widgets/CopyURI';
import { async } from 'regenerator-runtime';
import { ULPaginated } from './widgets/ULPaginated';
import { MostViewedUsecases } from './widgets/MostViewedUsecases';
import { Faq } from './widgets/Faq';
import { Breadcrumbs } from './widgets/Breadcrumbs';

var Handlebars = require('handlebars/runtime');

require("./utilities.js");

export class QelpCare {

    constructor(element) {
        if (!window.QelpCareCSR) window.QelpCareCSR = this;
        this.element = document.querySelector(element);
        this.selector = element;
        this.api = null;
        this.widgets = {
            register: (name, widget) => {
                this.widgets[name] = widget;
                this.widgets[name].parent = this;
                this.widgets[name].init();
                return widget;
            }
        };

        this.initiate = {
            breadcrumbs: () => {
                this.widgets.register('breadcrumbs', new Breadcrumbs('breadcrumbs'));
                this.widgets.breadcrumbs.simpleNavigate();
            },
            side_index: () => {
                this.widgets.register('side_index', new SideIndex('side-index'));
            },
        }

        this.html = {
            head: (title) => {
                document.querySelector("head title").innerHTML = title;
            }
        }

        this.pages = {
            home: async () => {
                let content = {};
                let aside = {};
                let side_index_itens = {};
                let manufacturers = await this.call("manufacturers");

                for (let element of this.settings.contents.home.main_content) {
                    try {
                        if (element.endpoint) {
                            let result;
                            if (typeof (element.endpoint) === "function") {
                                result = await element.endpoint();
                            } else {
                                result = await this.call(element.endpoint);
                            }
                            element.content = Object.assign(result, element?.content || {});
                            element.assets = this.settings.assets;
                        }
                        if (element.widget == "most_viewed_devices") {
                            element.content.forEach(item => {
                                item.manufacturerName = manufacturers.find(fitem => {
                                    return fitem.path == item.manufacturer;
                                }).name;
                            })
                        };
                        let html = this.settings.templates.widgets[element.widget](element);
                        content[element.widget] = {
                            html: html,
                        };
                    } catch (error) {
                        console.warn(error);
                    }
                    side_index_itens[element.widget] = element
                };
                for (let element of this.settings.contents.home.aside_panel) {
                    try {
                        if (element.endpoint) {
                            let result = await this.call(element.endpoint);
                            element.content = result;
                            element.assets = this.settings.assets;
                        }
                        let html = this.settings.templates.widgets[element.widget](element);
                        aside[element.widget] = {
                            html: html
                        };
                    } catch (error) {
                        console.warn(error);
                    }
                };
                this.html.head(this.translate("html/title"));
                this.render(
                    this.settings.templates.pages.home({
                        side_index: this.settings.templates.widgets.side_index({
                            list: side_index_itens
                        }),
                        content: content,
                        aside_panel: aside
                    }),
                    () => {
                        this.initiate.side_index();
                        this.widgets.register('manufacturers', new SimpleNavigate('manufacturers'));
                        this.widgets.register('most_viewed_devices', new SimpleNavigate('most_viewed_devices'));
                        this.widgets.register('most_viewed_usecases', new MostViewedUsecases('most_viewed_usecases'));
                        this.widgets.register('categories', new SimpleNavigate('categories'));
                        this.widgets.register('device_types', new SimpleNavigate('device_types'));
                        this.widgets.register('device_groups', new SimpleNavigate('device_groups'));
                        this.widgets.register('apps', new SimpleNavigate('apps'));
                        this.widgets.register('faq', new Faq('faq'));

                        let emulator_btn = this.element.querySelector(".device-emulator-btn");
                        if (emulator_btn) {
                            emulator_btn.addEventListener("click", e => {
                                this.router.navigateTo("emulator");
                            });
                        }
                    }
                );
            },
            softwares: async () => {
                let top_apps = await this.call("softwares/popular");
                let softwares = await this.call("softwares");

                let os_list = [];
                let oss = [];
                softwares.forEach(item => {
                    if (item.os) {
                        item.os.forEach(os => {
                            if (!os_list[os.path]) {
                                os_list[os.path] = os;
                            }
                        })
                    }
                });

                for (let os in os_list) {
                    oss.push(os_list[os]);
                }



                this.html.head(this.translate("html/title") + " - " + this.translate("page_title/applications"));



                this.render(
                    this.settings.templates.pages.softwares({
                        most_viewed_apps: this.settings.templates.widgets.most_viewed_apps({ top_apps: top_apps }),
                        softwares: softwares,
                        software_by_os: this.settings.templates.widgets.software_by_os({
                            title: this.translate("page/softwares/title"),
                            oss: oss,
                            softwares: softwares
                        }),
                        breadcrumbs: this.breadcrumbs([
                            {
                                name: this.translate("breadcrumbs/home"),
                                path: "/"
                            },
                            {
                                name: this.translate("breadcrumbs/applications"),
                            },
                        ])
                    }),
                    () => {
                        this.initiate.breadcrumbs();
                        this.widgets.register('most_viewed_apps', new SimpleNavigate("most_viewed_apps"));
                        this.widgets.register('software_by_os', new ItemByFilter('software-by-os'));
                        this.widgets.software_by_os.slide_element = true;

                    }
                );
            },
            dts: async (category_path, topic_path) => {
                let category = await this.getCategory(category_path);
                let topics = await this.call(`topics/category/${category.id}`);
                let topic = topics.find(item => {
                    return item.topicPath == topic_path;
                })

                let select = this.translate("page/dts/device");
                let devices = [];
                let devices_by_brand = [];
                let manufacturers = await this.call(`devices/topic/${topic_path}`);
                if (manufacturers.length>0) {
                    console.log("device");
                    manufacturers.forEach(manufacturer => {
                        manufacturer.deviceNumber = manufacturer.devices.length;
                        manufacturer.devices.forEach(item => {
                            item.manufacturerName = item.manufacturer;
                            item.manufacturerPath = manufacturer.path;
                        })
                        devices = devices.concat(manufacturer.devices);
                    });
                    devices_by_brand = this.settings.templates.widgets.dts_devices_by_brand({
                        title: this.translate("page/dts/title"),
                        description: this.translate("page/dts/description"),
                        category: category_path,
                        topic: topic_path,
                        devices: devices,
                        assets: this.settings.assets,
                        brands: manufacturers
                    });
                } else {
                    console.log("application");
                    select = this.translate("page/dts/os")
                    //manufacturers as OSs
                    manufacturers = await this.call(`softwares/topic/${topic_path}`);
                    manufacturers.forEach(manufacturer => {
                        manufacturer.softwares.forEach(software => {
                            software.os = manufacturer.os.path;
                            devices.push(software);
                        })
                    });
                    devices_by_brand = this.settings.templates.widgets.dts_software_by_os({
                        category: category_path,
                        topic: topic_path,
                        softwares: devices,
                        assets: this.settings.assets,
                        oss: manufacturers
                    });
                }

                if (manufacturers.length==1 && devices.length ==1)  {
                    console.log(manufacturers, devices);
                    this.router.navigateTo(manufacturers[0].path + "/" + devices[0].path + "/" + category.path  + "/" + topic.topicPath);
                    return;
                }

                if (process.env.NODE_ENV === 'development') {
                    console.log(devices);
                    console.log(manufacturers);
                }

                this.render(
                    this.settings.templates.pages.dts({
                        select: select,
                        topic: topic,
                        devices_by_brand: devices_by_brand,
                        breadcrumbs: this.breadcrumbs([
                            {
                                name: this.translate("breadcrumbs/home"),
                                path: "/"
                            },
                            {
                                name: this.translate("breadcrumbs/categories"),
                                path: this.getRouteByPath("categories")
                            },
                            {
                                name: category.name,
                                path: category.path
                            }
                        ])

                    }),
                    () => {
                        this.initiate.breadcrumbs();
                        this.widgets.register('devices_by_brand', new ItemByFilter('dts-item-by-filter'));
                        this.widgets.devices_by_brand.slide_element = true;
                    }
                );
            },
            device_group: async (group) => {

            },
            device_type: async (type) => {
                let types = await this.call(`devicetypes`);
                let top_devices = await this.call(`devices/popular/type/${type}`);
                let devices = await this.call(`devices/type/${type}`);
                let brands = await this.call(`manufacturers/type/${type}`);

                devices.forEach(item => {
                    const brand = brands.find(fitem => {
                        return fitem.path == item.manufacturer;
                    });
                    item.manufacturerName = brand?.name || null;
                })
                top_devices.forEach(item => {
                    item.manufacturerName = item.manufacturer;
                })

                let device_type = types.find(item => {
                    return item.path == type;
                })

                this.html.head(this.translate("html/title") + ` - ${device_type.name}`);

                this.render(
                    this.settings.templates.pages.device_type({
                        most_viewed_devices: this.settings.templates.widgets.most_viewed_devices({ content: top_devices }),
                        devices_by_brand: this.settings.templates.widgets.devices_by_brand({
                            title: device_type.name,
                            description: "Filtre por fabricante",
                            devices: devices,
                            assets: this.settings.assets,
                            brands: brands
                        }),
                        breadcrumbs: this.breadcrumbs([
                            {
                                name: this.translate("breadcrumbs/home"),
                                path: "/"
                            },
                            {
                                name: device_type.name,
                            },
                        ])
                    }),
                    () => {
                        this.initiate.breadcrumbs();
                        this.widgets.register("most_viewed_devices", new ULPaginated('most_viewed_devices'));
                        this.widgets.register('devices_by_brand', new ItemByFilter('devices-by-brand'));
                    }
                );

            },
            app: async (app_path) => {
                let app = await this.getSoftware(app_path);


                let topic_by_os = [];

                for (let os of app.os) {
                    let topics = await this.call(`topics/software/os/${app.id}/${os.id}`);
                    let categories = await this.groupByCategory(topics);
                    topic_by_os.push({
                        path: os.path,
                        html: this.settings.templates.widgets.categories_tutorials_app({
                            name: "Tutoriais",
                            os: os,
                            app: app,
                            categories: categories,
                        })
                    });
                }
                // console.log(topic_by_os);

                let top_usecases = await this.call(`topics/popular/software/${app.id}`);


                this.html.head(this.translate("html/title") + ` - ${app.name}`);


                this.render(
                    this.settings.templates.pages.app({
                        app: app,
                        topic_by_os: topic_by_os,
                        most_viewed_usecases: this.settings.templates.widgets.most_viewed_usecases({
                            content: top_usecases
                        }),
                        breadcrumbs: this.breadcrumbs([
                            {
                                name: this.translate("breadcrumbs/home"),
                                path: "/"
                            },
                            {
                                name: this.translate("breadcrumbs/applications"),
                                path: this.getRouteByPath("application")
                            },
                            {
                                name: app.name
                            }
                        ])
                    }),
                    () => {
                        this.initiate.breadcrumbs();
                        for (let os of app.os) {
                            this.widgets.register(`accordion_${os.path}`, new Accordion(`categories-tutorials-app.${os.path}`));
                        }
                        this.widgets.register('most_viewed_usecases', new MostViewedUsecases('most_viewed_usecases'));
                        this.widgets.register('tabs', new Tabs("tabs"));
                    }
                )

            },
            manufacturers: async () => {
                let manufacturers = await this.call("manufacturers");
                this.html.head(this.translate("html/title") + " - " + this.translate("page_title/manufacturers"));
                this.render(
                    this.settings.templates.pages.manufacturers({
                        manufacturers: manufacturers,
                        assets: this.settings.assets,
                        card_filter: this.settings.templates.widgets.card_filter({
                            placeholder: this.translate("page/manufacturers/card-filter/placeholder")
                        }),
                        manufacturer_list: this.settings.templates.widgets.manufacturer_list({
                            manufacturers: manufacturers,
                            assets: this.settings.assets,
                        }),
                        breadcrumbs: this.breadcrumbs([
                            {
                                name: this.translate("breadcrumbs/home"),
                                path: "/"
                            },
                            {
                                name: this.translate("breadcrumbs/manufacturers"),
                            },
                        ]),
                    }),
                    () => {
                        this.initiate.breadcrumbs();
                        this.widgets.register('manufacturers', new Widgets("manufacturer-list"));
                        this.widgets.manufacturers.simpleNavigate();
                        this.widgets.register('card_filter', new CardFilter("card-filter", ".manufacturer-list .card"));
                    }
                );
            },
            brand: async (path) => {
                let brand = await this.getManufacturer(path);

                if(!brand || !brand.id){
                    return false;
                }

                let devices = await this.call(`devices/manufacturer/${brand.id}`);
                this.html.head(this.translate("html/title") + " - " + brand.name);

                this.render(
                    this.settings.templates.pages.brand({
                        brand: brand,
                        devices: devices,
                        card_filter: this.settings.templates.widgets.card_filter({
                            placeholder: this.translate("page/brand/card-filter/placeholder")
                        }),
                        breadcrumbs: this.breadcrumbs([
                            {
                                name: this.translate("breadcrumbs/home"),
                                path: "/"
                            },
                            {
                                name: this.translate("breadcrumbs/manufacturers"),
                                path: this.getRouteByPath("manufacturers")
                            },
                            {
                                name: brand.name
                            }
                        ]),
                    }),
                    () => {
                        this.initiate.breadcrumbs();
                        this.widgets.register('device_list', new DeviceList("device-list"));
                        this.widgets.register('card_filter', new CardFilter("card-filter", ".device-list .card"));
                    }
                );
            },
            device: async (brand_path, device_path) => {
                let brand = await this.getManufacturer(brand_path);
                let device = await this.call(`devices/${device_path}`);
                let topics = await this.call(`topics/device/${device.id}`);
                let categories = await this.groupByCategory(topics);
                let top_usecases = await this.call(`topics/popular/device/${device.id}`);
                let manual_link = await this.getManualLink(topics);
                let iswrapped = this.settings.wrappers.find(item => {
                    return item == device.types[0].path;
                });

                let topic_by_os = {};
                let softwares = [];
                let popular_by_os = {}

                await Promise.all(device.os?.map(async os => {
                    let apps = await this.call(`softwares/os/${os.id}`);
                    if (apps?.length > 0) {
                        let app = apps[0];
                        let widget = (app.type?.path=="smartphone") ? 'categories_tutorials_oss' : 'categories_tutorials_app';
                        softwares.push(app);
                        let popular = await this.call(`topics/popular/software/${app.id}`);
                        popular_by_os[app.path] =  this.settings.templates.widgets[(app.type?.path=="smartphone") ? 'most_viewed_usecases_oss' : 'most_viewed_usecases']({
                            content: popular,
                        });
                        topics = await this.call(`topics/software/os/${app.id}/${os.id}`);
                        if (topics) {
                            let app_categories = await this.groupByCategory(topics);
                            topic_by_os[app.path] = {
                                path: app.path,
                                html: this.settings.templates.widgets[widget]({
                                    name: "Tutoriais",
                                    os: os,
                                    app: app,
                                    categories: app_categories,
                                })
                            };
                        }
                    }
                }));

                let specs;
                if (device.hasSpecs) {
                    specs = await this.call(`devices/${device.id}/specifications`);
                    for (let i = 0; i < specs?.specifications?.properties?.length; i++) {
                        let specifications = specs.specifications.properties[i];
                        for (let j = 0; j < specifications.settings.length; j++) {
                            let settings = specifications.settings[j];
                            if (settings.value == "True") {
                                settings.value = true;
                                settings.boolean = true;
                            }
                            if (settings.value == "False") {
                                settings.value = false;
                                settings.boolean = true;
                            }
                        }
                    }
                }
                this.html.head(this.translate("html/title") + " - " + device.name);
                this.render(
                    this.settings.templates.pages.device({
                        specs: (specs && this.settings.templates.widgets.specs) ? this.settings.templates.widgets.specs({specs:specs}) : specs,
                        brand: brand,
                        device: device,
                        categories: categories,
                        manual_link: manual_link,
                        iswrapped: iswrapped,
                        top_usecases: top_usecases,
                        most_viewed_usecases: this.settings.templates.widgets.most_viewed_usecases({
                            content: top_usecases,
                            perpage: 0
                        }),
                        categories_tutorials: this.settings.templates.widgets.categories_tutorials({
                            brand: brand,
                            device: device,
                            categories: categories,
                        }),
                        softwares: softwares,
                        topic_by_os: topic_by_os,
                        popular_by_os: popular_by_os,
                        breadcrumbs: this.breadcrumbs([
                            {
                                name: this.translate("breadcrumbs/home"),
                                path: "/"
                            },
                            {
                                name: this.translate("breadcrumbs/manufacturers"),
                                path: this.getRouteByPath("manufacturers")
                            },
                            {
                                name: brand.name,
                                path: `${this.getRouteByPath("manufacturers")}/${brand.path}`
                            },
                            {
                                name: device.name
                            }
                        ])
                    }),
                    () => {

                        this.initiate.breadcrumbs(); 
                        
                        this.widgets.register('most_viewed_usecases', new MostViewedUsecases('most_viewed_usecases'));

                        this.widgets.register('active-device-image', new DeviceImage('active-device-image'));

                        this.widgets.register('categories_tutorials', new Accordion("categories-tutorials:not(.categories-tutorials-app)"));

                        softwares.forEach(app=>{
                            let accordion_app = this.widgets.register('accordion_app_' + app.path, new Accordion(`categories-tutorials-app.${app.path}`));
                        })
                        const tabs = new Tabs("tabs");

                        tabs.element.addEventListener("TabWidgetSelected", e=>{
                            let tabs = this.element.querySelectorAll('.mostviewed-tabs');
                            console.log(tabs);
                            tabs.forEach(item => {
                                item.classList.remove("active");
                                if (item.classList.contains(e.detail.dataset.path)) {
                                    item.classList.add("active");
                                }
                            })
                        })
                        this.widgets.register('tabs', tabs);
                        if (specs) {
                            this.widgets.register('side_index', new SideIndex('side-menu'));
                            if (window.innerWidth > this.widgets.side_index.getCSSVariableNumber("--max-width")) {
                                this.widgets.side_index.scroll_element = document.querySelector(".widget.specs .content");
                            }
                            this.widgets.register('specs', new Specs('specs'));
                        }
                    }
                )
            },
            categories: async () => {
                let categories = await this.call(`categories`);

                this.html.head(this.translate("html/title") + " - " + this.translate("page_title/categories"));

                this.render(
                    this.settings.templates.pages.categories({
                        categories: categories,
                        card_filter: this.settings.templates.widgets.card_filter(),
                        breadcrumbs: this.breadcrumbs([
                            {
                                name: this.translate("breadcrumbs/home"),
                                path: "/"
                            },
                            {
                                name: this.translate("breadcrumbs/categories"),
                            },
                        ])
                    }),
                    () => {
                        this.initiate.breadcrumbs();
                        this.widgets.register('categories_list', new SimpleNavigate("categories-list"));
                        this.widgets.register('card_filter', new CardFilter("card-filter", ".categories-list .card"));

                    }
                );
            },
            category: async (category_path) => {
                let category = await this.getCategory(category_path);
                let topics = await this.call(`topics/category/${category.id}`);


                this.html.head(this.translate("html/title") + " - " + category.name);

                this.render(
                    this.settings.templates.pages.category({
                        category: category,
                        topics: topics,
                        breadcrumbs: this.breadcrumbs([
                            {
                                name: this.translate("breadcrumbs/home"),
                                path: "/"
                            },
                            {
                                name: this.translate("breadcrumbs/categories"),
                                path: this.getRouteByPath("categories")
                            },
                            {
                                name: category.name,
                            }
                        ])
                    }),
                    () => {
                        this.initiate.breadcrumbs();
                    }
                );
            },
            tutorial: async (brand_path, device_path, category_path, tutorial_path, choice = null) => {
   
                let brand = await this.getManufacturer(brand_path);
                let device = await this.call(`devices/${device_path}`);
                let topics = await this.call(`topics/device/${device.id}`);
                let topic = topics.find(item => {
                    return item.topicPath == tutorial_path;
                });

                if(!topic?.id){
                    return false;
                }
                
                let usecase = await this.call(`topics/${topic?.id}`);
                let breadcrumbs = this.breadcrumbs([
                    {
                        name: this.translate("breadcrumbs/home"),
                        path: "/"
                    },
                    {
                        name: this.translate("breadcrumbs/manufacturers"),
                        path: `${this.getRouteByPath("manufacturers")}`
                    },
                    {
                        name: brand.name,
                        path: `${this.getRouteByPath("manufacturers")}/${brand.path}`
                    },
                    {
                        name: device.name,
                        path: `${this.getRouteByPath("manufacturers")}/${brand.path}/${device.path}`
                    },
                    {
                        name: usecase.topicName,
                    }
                ])

                if (usecase.type == "choice") {
                    if (choice == null) {
                        this.render(
                            this.settings.templates.widgets.choices({
                                usecase: usecase,
                                brand_path: brand_path,
                                device_path: device_path,
                                category_path: category_path,
                                tutorial_path: tutorial_path,
                                breadcrumbs: breadcrumbs
                            }),
                            () => {
                                this.widgets.register('choices', new Choices('choices'));
                            }
                        );
                        return;
                    } else {
                        usecase = await this.call(`topics/${topic.id}/choice/${choice}`);
                    }
                }

                this.html.head(this.translate("html/title") + " - " + topic.topicName + " - " + device.name);
                this.render(
                    this.settings.templates.pages.usecase({
                        steps_navigation: this.settings.templates.widgets.steps_navigation(),
                        share_topic: this.settings.templates.widgets.share_topic(),
                        usecase: usecase,
                        brand: brand,
                        device: device,
                        breadcrumbs: breadcrumbs
                    }),
                    () => {
                        this.initiate.breadcrumbs();
                        for (let i = 0; i < usecase.steps.length; i++) {
                            let step = usecase.steps[i];
                            let image_selector = 'usecase-step-image-' + i;
                            let step_selector = '.usecase-step.step-' + i;
                            if (step.animation) {
                                this.widgets["qlicker" + i] = new Qlickr(step_selector, image_selector);
                            }
                            this.widgets[image_selector] = new DeviceImage(image_selector);
                        }

                        this.widgets.register('send_sms_form', new SMSSender("send-sms-form"));
                        this.widgets.register('copy_link', new CopyURI("copy-link"));
                        this.widgets.register('whatsapp_interface', new WhatsAppInterface("whatsapp-interface"));
                        this.widgets.register('send_email', new SendMail("send-email"));
                        this.widgets.register('steps_navigation', new StepsNavigation("topic-usecase", "steps-navigation", usecase));
                        this.widgets.register('share_topic', new Tabs("share-topic"));
                        
                        let aside = document.querySelector('.aside-content');
                        let btn_modal = document.querySelector('.send-tutorial');
                        let btn_back = document.querySelector('.share-topic-back');


                        let back_to_top = document.querySelector(".back-to-top");

                        back_to_top.addEventListener("click", e=>{
                            window.scrollTo({
                                top:0,
                                behavior: 'smooth'
                            })
                        })

                        if(btn_modal){
                            btn_modal?.addEventListener("click", e => {
                                aside.style.display = 'block';
                            });
                            btn_back?.addEventListener("click", e => {
                                aside.style.display = 'none';
                            });
                        }
                        
                    }
                );
            },
            app_tutorial: async (app_path, os_path, tutorial) => {
                console.log("app_tutorial");
                let app = await this.getSoftware(app_path);
                let os = app.os.find(item => {
                    return item.path == os_path
                })
                let topics = await this.call(`topics/software/${app.id}`);
                app.deviceImage = os.osImage;
                console.log(app);

                let topic = topics.find(item => {
                    return item.topicPath == tutorial && item.os.path == os_path;
                });
                console.log(topic);
                let usecase = await this.call(`topics/${topic.id}`);

                this.html.head(this.translate("html/title") + `- ${topic.topicName} - ${app.name}`);
                this.render(
                    this.settings.templates.pages.usecase({
                        steps_navigation: this.settings.templates.widgets.steps_navigation(),
                        share_topic: this.settings.templates.widgets.share_topic(),
                        usecase: usecase,
                        os: os,
                        app: app,
                        device: app,
                        breadcrumbs: this.breadcrumbs([
                            {
                                name: this.translate("breadcrumbs/home"),
                                path: "/"
                            },
                            {
                                name: this.translate("breadcrumbs/applications"),
                                path: `${this.settings.routes.application}`
                            },
                            {
                                name: app.name,
                                path: `${this.settings.routes.application}/${app.path}`
                            },
                            {
                                name: usecase.topicName,
                            }
                        ])
                    }),
                    () => {
                        this.initiate.breadcrumbs();
                        for (let i = 0; i < usecase.steps.length; i++) {
                            let step = usecase.steps[i];
                            let image_selector = 'usecase-step-image-' + i;
                            let step_selector = '.usecase-step.step-' + i;
                            this.widgets[image_selector] = new DeviceImage(image_selector);
                            if (step.animation) {
                                this.widgets["qlicker" + i] = new Qlickr(step_selector, image_selector);
                            }
                        }
                        
                        this.widgets.register('copy_link', new CopyURI("copy-link"));
                        this.widgets.register('send_sms_form', new SMSSender("send-sms-form"));
                        this.widgets.register('whatsapp_interface', new WhatsAppInterface("whatsapp-interface"));
                        this.widgets.register('send_email', new SendMail("send-email"));
                        this.widgets.register('steps_navigation', new StepsNavigation("topic-usecase", "steps-navigation", usecase));
                        this.widgets.register('share_topic', new Tabs("share-topic"));
                    }
                );
            }
        }

        this.loading = {
            start: () => {
                document.body.classList.add("loading")
                document.body.append(this.loading.template());
            },
            stop: () => {
                document.body.classList.remove("loading")
                document.querySelectorAll(".qc-loading").forEach(elem => {
                    elem.remove();
                })
            },
            template: () => {
                let modal = document.createElement("div");
                modal.id = "qc-loading";
                modal.classList.add("qc-loading", "loading");
                modal.innerHTML = "<p>" + this.translate("loading") + "</p>";
                return modal;
            }
        }


    }
    config(settings) {
        this.settings = settings;

        this.settings.api.forEach(item => {
            if (item.test.test(window.location.toString())) {
                this.api = axios.create({
                    baseURL: item.set,
                    timeout: 10000,
                    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
                })
            }
        });
    }
    async call(url) {
        return await (await this.api.get(`${url}`, { validateStatus: false })).data.data;
    }
    post(endpoint, callback, method, formdata = null, auth = null, json = false) {
        var xhttp = new XMLHttpRequest();
        let data = null;
        if (method.toUpperCase() == "POST" && formdata != null) {
            data = new FormData();

            for (let prop in formdata) {
                data.append(prop, formdata[prop]);
            }
        }
        xhttp.onreadystatechange = function () {
            if (this.readyState == 4) {
                if (this.status >= 200 && this.status < 300) {
                    if (xhttp.responseText) {
                        if (callback)
                            callback(JSON.parse(xhttp.responseText));
                    } else {
                        if (callback)
                            callback(null);
                    }
                } else {
                    window.alert("QelpCare warning: Server request error.");
                }
            }
        };
        let url = "";
        if (endpoint.match(/(http:\/\/)|(https:\/\/)/)) {
            url = endpoint;
        } else {
            url = this.config.api + endpoint;
        }
        xhttp.open(method, url, true);
        if (auth) {
            xhttp.setRequestHeader(auth.name, auth.key);
        }
        if (json) {
            xhttp.setRequestHeader("Content-Type", "application/json");
            data = JSON.stringify(data);
        }
        xhttp.send(data);
    }
    initRouter() {
        for (var i = 0; i < this.settings.root.length; i++) {
            // console.log(this.settings.root[i].test);
            if (this.settings.root[i].test.test(window.location.toString())) {
                console.log('match', this.settings.root[i].set);
                this.router = new Router({
                    mode: 'history',
                    root: this.settings.root[i].set,
                    page404: function (path) {
                        console.log('"/' + path + '" route not found');
                    }
                });
                break;
            }
        }
    }
    addDefaultRoutes() {
        if (this.settings.routes.application) this.router.add(`/${this.settings.routes.application}`, async () => {
            this.loading.start();
            this.pages.softwares();
        })
        if (this.settings.routes.categories) this.router.add(`/${this.settings.routes.categories}`, async () => {
            this.loading.start();
            this.pages.categories();
        })
        if (this.settings.routes.type) this.router.add(`/${this.settings.routes.type}/(:any)`, async (type) => {
            this.loading.start();
            this.pages.device_type(type);
        })
        if (this.settings.routes.group) this.router.add(`/${this.settings.routes.group}/(:any)`, async (type) => {
            this.loading.start();
            this.pages.device_group(type);
        })
        if (this.settings.routes.categories) this.router.add(`/${this.settings.routes.categories}/(:any)`, async (category) => {
            this.loading.start();
            this.pages.category(category);
        })
        if (this.settings.routes.manufacturers) this.router.add(`/${this.settings.routes.manufacturers}`, async () => {
            this.loading.start();
            this.pages.manufacturers();
        })
        if (this.settings.routes.manufacturers) this.router.add(`/${this.settings.routes.manufacturers}/(:any)`, async (brand) => {
            this.loading.start();
            this.pages.brand(brand);
        })
        if (this.settings.routes.manufacturers) this.router.add(`/${this.settings.routes.manufacturers}/(:any)/(:any)`, async (brand, device) => {
            this.loading.start();
            this.pages.device(brand, device);
        })
        if (this.settings.routes.application) this.router.add(`/${this.settings.routes.application}/(:any)`, async (app) => {
            this.loading.start();
            this.pages.app(app);
        })
        if (this.settings.routes.application) this.router.add(`/${this.settings.routes.application}/(:any)/(:any)/(:any)`, async (app, os, topic) => {
            this.loading.start();
            this.pages.app_tutorial(app, os, topic)
        })
        this.router.add(`/f/(:any)/(:any)`, async (category, topic) => {
            this.loading.start();
            this.pages.dts(category, topic);
        })
        this.router.add(`/(:any)`, async (brand) => {
            this.loading.start();
            this.pages.brand(brand);
        })
        this.router.add(`/(:any)/(:any)`, async (brand, device) => {
            this.loading.start();
            this.pages.device(brand, device);
        })
        this.router.add("/(:any)/(:any)/(:any)/(:any)", async (brand, device, category, topic) => {
            this.loading.start();
            this.pages.tutorial(brand, device, category, topic);
        })
    }
    async init() {
        this.initRouter();
        if (!this.router) {
            console.error("Cannot determine the root for the application!");
        } else {
            this.router.add("", async () => {
                this.loading.start();
                this.pages.home();
            })
            document.querySelector(".topper .qelp-logo").addEventListener("click", e => {
                this.router.navigateTo("");
            });
            this.router.addUriListener(() => {
                console.log("addUriListener:::");
                this.router.navigateTo(this.currentLocation(this.router.root));
            });
        }
    }
    currentLocation(root) {
        let url = window.location.toString().split(root)[1];
        console.log("currentLocation", url, root);
        return url;
    }
    breadcrumbs(data) {
        return this.settings.templates.widgets.breadcrumbs({
            section: data
        });
    }




    async groupByCategory(topics) {
        let categories = [];
        for (let i = 0; i < topics.length; i++) {
            let topic = topics[i];
            if (topic.category.type != 'guided-faq' &&
                topic.category.path != 'manual'
            ) {
                if (!categories[topic.category.id]) {
                    categories[topic.category.id] = topic.category;
                }
                if (!categories[topic.category.id].topics) {
                    categories[topic.category.id].topics = [];
                }
                categories[topic.category.id].topics.push(topic);
            }
        }
        return categories;
    }

    async getManufacturer(path) {
        let manufacturers = await this.call('manufacturers');
        return manufacturers.find(item => {
            return item.path == path;
        });
    }
    async getSoftware(path) {
        let softwares = await this.call('softwares');
        return softwares.find(item => {
            return item.path == path;
        });
    }
    async getCategory(path) {
        let categories = await this.call('categories');
        return categories.find(item => {
            return item.path == path;
        });
    }
    async getManualLink(topics) {
        try {
            let manual = topics.find(item => {
                return item.category.path == "manual";
            });
            if (manual) {
                let usecase = await this.call(`topics/${manual.id}`);

                let div = document.createElement('div');
                div.innerHTML = usecase.steps[0].text;

                let list = div.querySelectorAll("a");
                let a;

                list.forEach(elem => {
                    if (!elem.href.match(/get.adobe.com\/reader/)) {
                        a = elem;
                    }
                });
                if (a) {
                    return a.href;
                } else {
                    return null;
                }
            } else {
                return null;
            }
        } catch (e) {
            console.log(e);
        }
    }

    async render(html, callback) {
        console.log("Page Rendered");
        this.element.innerHTML = html;
        this.loading.stop();
        window.scrollTo(0, 0);
        if (callback) {
            callback();
            //deeplink
            document.querySelectorAll("a").forEach(item => {
                if (item.href.toString().match(/deeplink/i)) {
                    item.dataset.navigateTo = `${item.dataset.manufacturerpath}/${item.dataset.devicepath}/${item.dataset.categorypath}/${item.dataset.topicpath}`
                    item.href = "/" + item.dataset.navigateTo;
                    // console.log(item);
                }
            });
        }
        let e = new Event("PageRendered");
        this.element.dispatchEvent(e);
        window.dataLayer = window.dataLayer || [];
        dataLayer.push({ 'event': 'pageload' });
    }
    getRouteByPath(path) {
        return "/" + this.settings.routes[path];
    }
    translate(path_str) {
        try {
            let locale = this.settings.localization[this.settings.locale];
            let path = "['" + path_str.replace(/\//g, "']['") + "']";
            return eval(`locale${path}`);
        } catch (e) {
            path_str;
        }
    }
    ready(callback) {
        this.init();
        if (callback) callback();
        this.addDefaultRoutes();
        this.router.navigateTo(this.currentLocation(this.router.root));
    }
}
var Handlebars = require("../../../../CSR_QelpCare_Projects/csr-claro-br/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"usecase-step step-"
    + alias4(((helper = (helper = lookupProperty(helpers,"index") || (data && lookupProperty(data,"index"))) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data,"loc":{"start":{"line":15,"column":42},"end":{"line":15,"column":52}}}) : helper)))
    + "\" data-step=\""
    + alias4((lookupProperty(helpers,"math")||(depth0 && lookupProperty(depth0,"math"))||alias2).call(alias1,(data && lookupProperty(data,"index")),"+",1,{"name":"math","hash":{},"data":data,"loc":{"start":{"line":15,"column":65},"end":{"line":15,"column":86}}}))
    + "\" animation=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"animation") || (depth0 != null ? lookupProperty(depth0,"animation") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"animation","hash":{},"data":data,"loc":{"start":{"line":15,"column":99},"end":{"line":15,"column":112}}}) : helper)))
    + "\">\n\n                <div class=\"content\">\n                    <div class=\"content-wrapper\">\n                        <div class=\"step-number\">\n                            "
    + alias4((lookupProperty(helpers,"math")||(depth0 && lookupProperty(depth0,"math"))||alias2).call(alias1,(data && lookupProperty(data,"index")),"+",1,{"name":"math","hash":{},"data":data,"loc":{"start":{"line":20,"column":28},"end":{"line":20,"column":49}}}))
    + "\n                        </div>\n                        <div class=\"step-content\">\n                            "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"text") || (depth0 != null ? lookupProperty(depth0,"text") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"text","hash":{},"data":data,"loc":{"start":{"line":23,"column":28},"end":{"line":23,"column":38}}}) : helper))) != null ? stack1 : "")
    + "\n                        </div>\n                    </div>\n                </div>\n\n\n\n                <div class=\"widget usecase-step-image usecase-step-image-"
    + alias4(((helper = (helper = lookupProperty(helpers,"index") || (data && lookupProperty(data,"index"))) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data,"loc":{"start":{"line":30,"column":73},"end":{"line":30,"column":83}}}) : helper)))
    + "\">\n\n                    <!-- Wrapped Start Screen and Device Image-->\n"
    + ((stack1 = (lookupProperty(helpers,"ifEquals")||(depth0 && lookupProperty(depth0,"ifEquals"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"hardwareImage") : depth0),true,{"name":"ifEquals","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.program(5, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":33,"column":20},"end":{"line":74,"column":33}}})) != null ? stack1 : "")
    + "                    <!-- Wrapped Start Screen and Device Image-->\n\n                </div>\n\n            </div>\n";
},"2":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, alias5=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <div class=\"image\" \n                        data-hardware-image="
    + alias4(((helper = (helper = lookupProperty(helpers,"hardwareImage") || (depth0 != null ? lookupProperty(depth0,"hardwareImage") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"hardwareImage","hash":{},"data":data,"loc":{"start":{"line":35,"column":44},"end":{"line":35,"column":61}}}) : helper)))
    + " \n                        data-has-wrapper=\""
    + alias4(alias5(((stack1 = (depths[1] != null ? lookupProperty(depths[1],"device") : depths[1])) != null ? lookupProperty(stack1,"hasWrapper") : stack1), depth0))
    + "\"\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"coordinates") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":37,"column":24},"end":{"line":40,"column":31}}})) != null ? stack1 : "")
    + "                        data-screen-size-x=\""
    + alias4(alias5(((stack1 = ((stack1 = ((stack1 = (depths[1] != null ? lookupProperty(depths[1],"device") : depths[1])) != null ? lookupProperty(stack1,"deviceImage") : stack1)) != null ? lookupProperty(stack1,"screenSize") : stack1)) != null ? lookupProperty(stack1,"x") : stack1), depth0))
    + "\"\n                        data-screen-size-y=\""
    + alias4(alias5(((stack1 = ((stack1 = ((stack1 = (depths[1] != null ? lookupProperty(depths[1],"device") : depths[1])) != null ? lookupProperty(stack1,"deviceImage") : stack1)) != null ? lookupProperty(stack1,"screenSize") : stack1)) != null ? lookupProperty(stack1,"y") : stack1), depth0))
    + "\"\n                        data-image-size-x=\""
    + alias4(alias5(((stack1 = ((stack1 = ((stack1 = (depths[1] != null ? lookupProperty(depths[1],"device") : depths[1])) != null ? lookupProperty(stack1,"deviceImage") : stack1)) != null ? lookupProperty(stack1,"imageSize") : stack1)) != null ? lookupProperty(stack1,"x") : stack1), depth0))
    + "\"\n                        data-image-size-y=\""
    + alias4(alias5(((stack1 = ((stack1 = ((stack1 = (depths[1] != null ? lookupProperty(depths[1],"device") : depths[1])) != null ? lookupProperty(stack1,"deviceImage") : stack1)) != null ? lookupProperty(stack1,"imageSize") : stack1)) != null ? lookupProperty(stack1,"y") : stack1), depth0))
    + "\"\n                        data-screen-position-x=\""
    + alias4(alias5(((stack1 = ((stack1 = ((stack1 = (depths[1] != null ? lookupProperty(depths[1],"device") : depths[1])) != null ? lookupProperty(stack1,"deviceImage") : stack1)) != null ? lookupProperty(stack1,"screenPosition") : stack1)) != null ? lookupProperty(stack1,"x") : stack1), depth0))
    + "\"\n                        data-screen-position-y=\""
    + alias4(alias5(((stack1 = ((stack1 = ((stack1 = (depths[1] != null ? lookupProperty(depths[1],"device") : depths[1])) != null ? lookupProperty(stack1,"deviceImage") : stack1)) != null ? lookupProperty(stack1,"screenPosition") : stack1)) != null ? lookupProperty(stack1,"y") : stack1), depth0))
    + "\"\n                        data-offset-x=\""
    + alias4(alias5(((stack1 = ((stack1 = ((stack1 = (depths[1] != null ? lookupProperty(depths[1],"device") : depths[1])) != null ? lookupProperty(stack1,"deviceImage") : stack1)) != null ? lookupProperty(stack1,"offset") : stack1)) != null ? lookupProperty(stack1,"x") : stack1), depth0))
    + "\"\n                        data-offset-y=\""
    + alias4(alias5(((stack1 = ((stack1 = ((stack1 = (depths[1] != null ? lookupProperty(depths[1],"device") : depths[1])) != null ? lookupProperty(stack1,"deviceImage") : stack1)) != null ? lookupProperty(stack1,"offset") : stack1)) != null ? lookupProperty(stack1,"y") : stack1), depth0))
    + "\">\n                        <div class=\"image-wrapper\">\n                            <img class=\"hardware\" src=\""
    + alias4(alias5(((stack1 = ((stack1 = (depths[1] != null ? lookupProperty(depths[1],"device") : depths[1])) != null ? lookupProperty(stack1,"deviceImage") : stack1)) != null ? lookupProperty(stack1,"imageURL") : stack1), depth0))
    + "\" alt=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":50,"column":96},"end":{"line":50,"column":104}}}) : helper)))
    + "\">\n                            <img class=\"startScreen\" src=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"imageURL") || (depth0 != null ? lookupProperty(depth0,"imageURL") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"imageURL","hash":{},"data":data,"loc":{"start":{"line":51,"column":58},"end":{"line":51,"column":70}}}) : helper)))
    + "\" alt=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":51,"column":77},"end":{"line":51,"column":85}}}) : helper)))
    + "\">\n                        </div>\n                    </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        data-coordinates-x=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"coordinates") : depth0)) != null ? lookupProperty(stack1,"left") : stack1), depth0))
    + "\"\n                        data-coordinates-y=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"coordinates") : depth0)) != null ? lookupProperty(stack1,"top") : stack1), depth0))
    + "\"\n";
},"5":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, alias5=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <div class=\"image\" \n                        data-hardware-image="
    + alias4(((helper = (helper = lookupProperty(helpers,"hardwareImage") || (depth0 != null ? lookupProperty(depth0,"hardwareImage") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"hardwareImage","hash":{},"data":data,"loc":{"start":{"line":56,"column":44},"end":{"line":56,"column":61}}}) : helper)))
    + " \n                        data-has-wrapper=\""
    + alias4(alias5(((stack1 = (depths[1] != null ? lookupProperty(depths[1],"device") : depths[1])) != null ? lookupProperty(stack1,"hasWrapper") : stack1), depth0))
    + "\"\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"coordinates") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":58,"column":24},"end":{"line":61,"column":31}}})) != null ? stack1 : "")
    + "                        data-screen-size-x=\""
    + alias4(alias5(((stack1 = ((stack1 = ((stack1 = (depths[1] != null ? lookupProperty(depths[1],"device") : depths[1])) != null ? lookupProperty(stack1,"deviceImage") : stack1)) != null ? lookupProperty(stack1,"screenSize") : stack1)) != null ? lookupProperty(stack1,"x") : stack1), depth0))
    + "\"\n                        data-screen-size-y=\""
    + alias4(alias5(((stack1 = ((stack1 = ((stack1 = (depths[1] != null ? lookupProperty(depths[1],"device") : depths[1])) != null ? lookupProperty(stack1,"deviceImage") : stack1)) != null ? lookupProperty(stack1,"screenSize") : stack1)) != null ? lookupProperty(stack1,"y") : stack1), depth0))
    + "\"\n                        data-image-size-x=\""
    + alias4(alias5(((stack1 = ((stack1 = ((stack1 = (depths[1] != null ? lookupProperty(depths[1],"device") : depths[1])) != null ? lookupProperty(stack1,"deviceImage") : stack1)) != null ? lookupProperty(stack1,"imageSize") : stack1)) != null ? lookupProperty(stack1,"x") : stack1), depth0))
    + "\"\n                        data-image-size-y=\""
    + alias4(alias5(((stack1 = ((stack1 = ((stack1 = (depths[1] != null ? lookupProperty(depths[1],"device") : depths[1])) != null ? lookupProperty(stack1,"deviceImage") : stack1)) != null ? lookupProperty(stack1,"imageSize") : stack1)) != null ? lookupProperty(stack1,"y") : stack1), depth0))
    + "\"\n                        data-screen-position-x=\""
    + alias4(alias5(((stack1 = ((stack1 = ((stack1 = (depths[1] != null ? lookupProperty(depths[1],"device") : depths[1])) != null ? lookupProperty(stack1,"deviceImage") : stack1)) != null ? lookupProperty(stack1,"screenPosition") : stack1)) != null ? lookupProperty(stack1,"x") : stack1), depth0))
    + "\"\n                        data-screen-position-y=\""
    + alias4(alias5(((stack1 = ((stack1 = ((stack1 = (depths[1] != null ? lookupProperty(depths[1],"device") : depths[1])) != null ? lookupProperty(stack1,"deviceImage") : stack1)) != null ? lookupProperty(stack1,"screenPosition") : stack1)) != null ? lookupProperty(stack1,"y") : stack1), depth0))
    + "\"\n                        data-offset-x=\""
    + alias4(alias5(((stack1 = ((stack1 = ((stack1 = (depths[1] != null ? lookupProperty(depths[1],"device") : depths[1])) != null ? lookupProperty(stack1,"deviceImage") : stack1)) != null ? lookupProperty(stack1,"offset") : stack1)) != null ? lookupProperty(stack1,"x") : stack1), depth0))
    + "\"\n                        data-offset-y=\""
    + alias4(alias5(((stack1 = ((stack1 = ((stack1 = (depths[1] != null ? lookupProperty(depths[1],"device") : depths[1])) != null ? lookupProperty(stack1,"deviceImage") : stack1)) != null ? lookupProperty(stack1,"offset") : stack1)) != null ? lookupProperty(stack1,"y") : stack1), depth0))
    + "\">\n                        <div class=\"image-wrapper\">\n                            <img class=\"startScreen\" src=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"imageURL") || (depth0 != null ? lookupProperty(depth0,"imageURL") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"imageURL","hash":{},"data":data,"loc":{"start":{"line":71,"column":58},"end":{"line":71,"column":70}}}) : helper)))
    + "\" alt=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":71,"column":77},"end":{"line":71,"column":85}}}) : helper)))
    + "\">\n                        </div>\n                    </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<!-- Usecase Template -->\n<div class=\"page usecase "
    + alias4(((helper = (helper = lookupProperty(helpers,"css_class") || (depth0 != null ? lookupProperty(depth0,"css_class") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"css_class","hash":{},"data":data,"loc":{"start":{"line":2,"column":25},"end":{"line":2,"column":38}}}) : helper)))
    + "\">\n    <div class=\"wrapper\">\n        "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"breadcrumbs") || (depth0 != null ? lookupProperty(depth0,"breadcrumbs") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"breadcrumbs","hash":{},"data":data,"loc":{"start":{"line":4,"column":8},"end":{"line":4,"column":25}}}) : helper))) != null ? stack1 : "")
    + "\n        <h1 class=\"page-title\">"
    + alias4(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"usecase") : depth0)) != null ? lookupProperty(stack1,"topicName") : stack1), depth0))
    + "</h1>\n\n        <div class=\"widget topic-usecase\">\n\n            <button class=\"primary send-tutorial hide-desktop\">\n                <i class=\"material-icons-outlined\">share</i>\n                <span>"
    + alias4((lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"page/usecase/send",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":11,"column":22},"end":{"line":11,"column":55}}}))
    + "</span>\n            </button>\n            \n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"usecase") : depth0)) != null ? lookupProperty(stack1,"steps") : stack1),{"name":"each","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":12},"end":{"line":80,"column":21}}})) != null ? stack1 : "")
    + "            <div class=\"back-to-top\"><span>"
    + alias4((lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"page/usecase/back-to-top",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":81,"column":43},"end":{"line":81,"column":83}}}))
    + "</span></div>\n\n        </div>\n        "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"steps_navigation") || (depth0 != null ? lookupProperty(depth0,"steps_navigation") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"steps_navigation","hash":{},"data":data,"loc":{"start":{"line":84,"column":8},"end":{"line":84,"column":30}}}) : helper))) != null ? stack1 : "")
    + "\n    </div>\n    <div class=\"aside-content\">\n        "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"share_topic") || (depth0 != null ? lookupProperty(depth0,"share_topic") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"share_topic","hash":{},"data":data,"loc":{"start":{"line":87,"column":8},"end":{"line":87,"column":25}}}) : helper))) != null ? stack1 : "")
    + "\n    </div>\n</div>";
},"useData":true,"useDepths":true});
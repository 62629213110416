import { Widgets } from "./Widgets";

export class Breadcrumbs extends Widgets {
  constructor(selector) {
    super(selector);
    let items = this.element.querySelectorAll("li");
    if (items) {
      items.forEach((element) => {
        let path = element.dataset.path;
        if (path) {
          let classes = path.split("/");
          classes.forEach((item) => {
            if (item) element.classList.add(item);
          });
        }
      });
    }
  }
}

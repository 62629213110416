var Handlebars = require("../../../../CSR_QelpCare_Projects/csr-claro-br/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<!-- Device Template -->\n<div class=\"page device app\">\n    <div class=\"wrapper\">\n        "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"breadcrumbs") || (depth0 != null ? lookupProperty(depth0,"breadcrumbs") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"breadcrumbs","hash":{},"data":data,"loc":{"start":{"line":4,"column":8},"end":{"line":4,"column":25}}}) : helper))) != null ? stack1 : "")
    + "\n        <h1 class=\"page-title\">"
    + alias4(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"app") : depth0)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "</h1>\n        <div class=\"widget tabs\">\n            "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"topic_by_os") || (depth0 != null ? lookupProperty(depth0,"topic_by_os") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"topic_by_os","hash":{},"data":data,"loc":{"start":{"line":7,"column":12},"end":{"line":7,"column":29}}}) : helper))) != null ? stack1 : "")
    + "\n        </div>\n    </div>\n    <div class=\"aside-content\">\n        <div class=\"device-details\">\n            <div class=\"device-images\">\n                <div class=\"widget active-device-image\">\n                    <div class=\"image\">\n                        <img class=\"startScreen\" src=\""
    + alias4((lookupProperty(helpers,"resizeImage")||(depth0 && lookupProperty(depth0,"resizeImage"))||alias2).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"app") : depth0)) != null ? lookupProperty(stack1,"coverImageUrl") : stack1),"240x250",{"name":"resizeImage","hash":{},"data":data,"loc":{"start":{"line":15,"column":54},"end":{"line":15,"column":97}}}))
    + "\" alt=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":15,"column":104},"end":{"line":15,"column":112}}}) : helper)))
    + "\">\n                    </div>\n                </div>\n            </div>\n            <div class=\"buttons\">\n\n            </div>\n            "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"most_viewed_usecases") || (depth0 != null ? lookupProperty(depth0,"most_viewed_usecases") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"most_viewed_usecases","hash":{},"data":data,"loc":{"start":{"line":22,"column":12},"end":{"line":22,"column":38}}}) : helper))) != null ? stack1 : "")
    + "\n        </div>\n    </div>\n</div>\n<!-- End Device Template -->";
},"useData":true});
var Handlebars = require("../../../../CSR_QelpCare_Projects/csr-claro-br/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<!-- DTS Template -->\n<div class=\"page dts\">\n    <div class=\"page-header\">\n        <div class=\"wrapper\">\n            "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"breadcrumbs") || (depth0 != null ? lookupProperty(depth0,"breadcrumbs") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"breadcrumbs","hash":{},"data":data,"loc":{"start":{"line":5,"column":12},"end":{"line":5,"column":29}}}) : helper))) != null ? stack1 : "")
    + "\n            <h1 class=\"page-title\">"
    + alias4(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"topic") : depth0)) != null ? lookupProperty(stack1,"topicName") : stack1), depth0))
    + "</h1>\n\n            <p class=\"warning\">\n                <i class=\"material-icons\">\n                    report_problem\n                </i>\n                "
    + alias4((lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"page/dts/to_see_this_tutorial",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":12,"column":16},"end":{"line":12,"column":62}}}))
    + " "
    + alias4(((helper = (helper = lookupProperty(helpers,"select") || (depth0 != null ? lookupProperty(depth0,"select") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"select","hash":{},"data":data,"loc":{"start":{"line":12,"column":63},"end":{"line":12,"column":73}}}) : helper)))
    + "\n            </p>\n        </div>\n    </div>\n    <div class=\"page-wrapper\">\n        "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"devices_by_brand") || (depth0 != null ? lookupProperty(depth0,"devices_by_brand") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"devices_by_brand","hash":{},"data":data,"loc":{"start":{"line":17,"column":8},"end":{"line":17,"column":30}}}) : helper))) != null ? stack1 : "")
    + "\n    </div>\n</div>\n<!-- End DTS Template -->";
},"useData":true});
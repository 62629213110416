var Handlebars = require('handlebars/runtime');

Handlebars.registerHelper('root', function () {
    return QelpCareCSR.router.root;
});
Handlebars.registerHelper('route', function ($route) {
    return QelpCareCSR.settings.routes[$route];
});
Handlebars.registerHelper('translate', function (path_str) {
    try {
        let locale = QelpCareCSR.settings.localization[QelpCareCSR.settings.locale];
        let path = "['" + path_str.replace(/\//g, "']['") + "']";
        return eval(`locale${path}`);
    } catch (error) {
        return path_str;
    }
});
Handlebars.registerHelper('concat', function (str1, str2) {
    return String(str1 + str2);
});
Handlebars.registerHelper('pluralize', function (number, path_str) {
    try {
        let locale = QelpCareCSR.settings.localization[QelpCareCSR.settings.locale];
        let path = "['" + path_str.replace(/\//g, "']['") + "']";
        let options = eval(`locale${path}`);
        if (number === 0) {
            return options['none'];
        } else if (number === 1) {
            return options['single'];
        } else {
            return options['plural'];
        }
    } catch (error) {
        console.warn(error);
        console.log(path_str);
    }

});
Handlebars.registerHelper('ifEquals', function (arg1, arg2, options) {
    return (arg1 == arg2) ? options.fn(this) : options.inverse(this);
});
Handlebars.registerHelper('resizeImage', function (url, size) {
    if (!url) return;
    let params = url.split("/");
    let filename = params.pop();
    params.push(size)
    params.push(filename);
    return params.join("/");
});
Handlebars.registerHelper("math", function (lvalue, operator, rvalue) {
    lvalue = parseFloat(lvalue);
    rvalue = parseFloat(rvalue);
    return {
        "+": lvalue + rvalue,
        "-": lvalue - rvalue,
        "*": lvalue * rvalue,
        "/": lvalue / rvalue,
        "%": lvalue % rvalue
    }[operator];
});

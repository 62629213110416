import { Widgets } from "./Widgets";

export class CardFilter extends Widgets {
    constructor(selector, target) {
        super(selector);
        this.target = document.querySelectorAll(target);
        this.element.querySelector('input[type=text]').addEventListener("keyup", e => {
            if (e.keyCode==27) {
                e.currentTarget.value = "";
            }

            let search = e.currentTarget.value;
            // console.log(e.keyCode);

            this.target.forEach(elemn => {
                // console.log(elemn.classList.contains(search));
                let query = RegExp(`${search}`, 'gi');
                if (elemn.querySelector(".title").innerText.match(query)
                    || elemn.classList.value.match(query)
                ) {
                    elemn.style.display = "";
                } else {
                    elemn.style.display = "none";
                }
            });
        });
        this.element.querySelector('input[type=text]').focus();
    }
}
import { Widgets } from "./Widgets";

export class ItemByFilter extends Widgets {
    constructor(selector) {
        super(selector);
        this.slide_element = true;
        this.filters = this.element.querySelectorAll(".filter-list .card");
        this.items = this.element.querySelectorAll(".item-list .card");
        this.panel = this.element.querySelector(".item-list .panel");
        this.filters.forEach(elem => {
            elem.addEventListener("click", e => {
                e.preventDefault();
                let element = e.currentTarget;
                this.select(element);
            });
        })
        this.select(this.filters[0]);
        this.simpleNavigate(this.panel);
    }
    select(element) {
        if (this.slide_element) {
            this.panel.style.top = element.offsetTop + "px";
        }
        this.filters.forEach(elm => {
            if (elm != element) {
                elm.classList.remove("active");
            } else {
                elm.classList.add("active");
            }
        })
        this.items.forEach(elm => {
            if (elm.classList.contains(element.dataset.path)) {
                elm.classList.add("active");
            } else {
                elm.classList.remove("active");
            }
        })

    }
}
var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <li class=\"card "
    + alias4(((helper = (helper = lookupProperty(helpers,"path") || (depth0 != null ? lookupProperty(depth0,"path") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"path","hash":{},"data":data,"loc":{"start":{"line":14,"column":32},"end":{"line":14,"column":40}}}) : helper)))
    + "\">\n                    <a href=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"root") || (depth0 != null ? lookupProperty(depth0,"root") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"root","hash":{},"data":data,"loc":{"start":{"line":15,"column":29},"end":{"line":15,"column":37}}}) : helper)))
    + alias4((lookupProperty(helpers,"route")||(depth0 && lookupProperty(depth0,"route"))||alias2).call(alias1,"categories",{"name":"route","hash":{},"data":data,"loc":{"start":{"line":15,"column":37},"end":{"line":15,"column":59}}}))
    + "/"
    + alias4(((helper = (helper = lookupProperty(helpers,"path") || (depth0 != null ? lookupProperty(depth0,"path") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"path","hash":{},"data":data,"loc":{"start":{"line":15,"column":60},"end":{"line":15,"column":68}}}) : helper)))
    + "\" data-navigate-to=\""
    + alias4((lookupProperty(helpers,"route")||(depth0 && lookupProperty(depth0,"route"))||alias2).call(alias1,"categories",{"name":"route","hash":{},"data":data,"loc":{"start":{"line":15,"column":88},"end":{"line":15,"column":110}}}))
    + "/"
    + alias4(((helper = (helper = lookupProperty(helpers,"path") || (depth0 != null ? lookupProperty(depth0,"path") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"path","hash":{},"data":data,"loc":{"start":{"line":15,"column":111},"end":{"line":15,"column":119}}}) : helper)))
    + "\" class=\"wrapper\">\n                        <div class=\"image\">\n                            <i class=\"mdn-Icon mdn-Icon-"
    + alias4(((helper = (helper = lookupProperty(helpers,"iconName") || (depth0 != null ? lookupProperty(depth0,"iconName") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"iconName","hash":{},"data":data,"loc":{"start":{"line":17,"column":56},"end":{"line":17,"column":68}}}) : helper)))
    + "\"></i>\n                        </div>\n                        <div class=\"details\">\n                            <h3 class=\"title\">\n                                "
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":21,"column":32},"end":{"line":21,"column":40}}}) : helper)))
    + "\n                            </h3>\n                        </div>\n                        <div class=\"icon-holder\">\n                            <i class=\"material-icons\">chevron_right</i>\n                        </div>\n                    </a>\n                </li>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<!-- Categories Template -->\n<div class=\"page categories full\">\n    <div class=\"page-header\">\n        <div class=\"wrapper\">\n            "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"breadcrumbs") || (depth0 != null ? lookupProperty(depth0,"breadcrumbs") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"breadcrumbs","hash":{},"data":data,"loc":{"start":{"line":5,"column":12},"end":{"line":5,"column":29}}}) : helper))) != null ? stack1 : "")
    + "\n            <h1 class=\"page-title\">"
    + container.escapeExpression((lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"page/categories/title",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":6,"column":35},"end":{"line":6,"column":72}}}))
    + "</h1>\n            "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"card_filter") || (depth0 != null ? lookupProperty(depth0,"card_filter") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"card_filter","hash":{},"data":data,"loc":{"start":{"line":7,"column":12},"end":{"line":7,"column":29}}}) : helper))) != null ? stack1 : "")
    + "\n        </div>\n    </div>\n    <div class=\"page-wrapper\">\n        <div class=\"widget categories-list\">\n            <ul class=\"panel\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"categories") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":16},"end":{"line":29,"column":25}}})) != null ? stack1 : "")
    + "            </ul>\n        </div>\n    </div>\n</div>\n<!-- End Categories Template -->\n";
},"useData":true});
import { Widgets } from "./Widgets";
const sanitizeHtml = require('sanitize-html');

export class StepsNavigation extends Widgets {
    constructor(target, navigator, usecase) {
        super(navigator)
        this.target = document.querySelector(`.widget.${target}`);
        this.scroll_element = document.querySelector(".page.usecase .wrapper");
        this.current_step = this.target.querySelector(".usecase-step");
        this.counter = this.element.querySelector(".counter");
        this.steps = this.target.querySelectorAll(".usecase-step");
        this.preview_steps_panel = this.element.querySelector(".preview-steps-panel");
        this.open_side_bar = this.element.querySelector(".open-side-bar");

        this.open_side_bar.addEventListener("click", e=>{
            this.preview_steps_panel.classList.toggle("active");
            this.open_side_bar.querySelector(".open").classList.toggle("active");
            this.open_side_bar.querySelector(".close").classList.toggle("active");
        });

        this.element.querySelectorAll(".arrows").forEach(item => {
            item.addEventListener("click", e => {
                this.preview_steps_panel.classList.remove("active");
                this.open_side_bar.querySelector(".open").classList.add("active");
                this.open_side_bar.querySelector(".close").classList.remove("active");
                    let next = this.current_step;
                if (e.currentTarget.classList.contains("arrow_up")) {
                    if (this.current_step.previousElementSibling) {
                        next = this.current_step.previousElementSibling
                    }
                } else {
                    if (this.current_step.nextElementSibling) {
                        next = this.current_step.nextElementSibling
                    }
                }
                this.scrollToStep(next);
            });
        })

        this.scroll_element.addEventListener("scroll", e => {

            for (let i = 0; i < this.steps.length; i++) {
                let offset = this.steps[i].getBoundingClientRect();
                if (offset.top + (offset.height / 2) > 0) {
                    this.current_step = this.steps[i];
                    this.update();
                    break;
                }
            }
        });

        let ul = document.createElement("ul");
        for (let i = 0; i < usecase.steps.length; i++) {
            let item = usecase.steps[i];
            let substr = sanitizeHtml(String(item.text.replace(/(<([^>]+)>)|<br>|[\n\t]/ig, "").trim()).substr(0, 25) + "...");
            let li = document.createElement("li");
            li.addEventListener("click", e=>{
                this.scrollToStep(this.target.querySelector(`.usecase-step[data-step="${e.currentTarget.dataset.step}"]`));
            });

            li.innerText = substr;
            li.dataset.step = i+1;
            li.classList.add("step-preview");
            ul.append(li);
        };
        document.querySelector(".preview-steps-panel .panel").append(ul);
        this.update();
    }
    scrollToStep(element) {
        this.scroll_element.scrollTo({
            top: element.offsetTop + element.parentElement.offsetTop,
            behavior: 'smooth',
        });
    }
    update() {
        this.counter.innerHTML = `<span>${this.current_step.dataset.step}</span><span>${this.steps.length}</span>`
        this.preview_steps_panel.querySelectorAll(".step-preview").forEach(item=>{
            if (Number(item.dataset.step) < Number(this.current_step.dataset.step)) {
                item.classList.remove('active');
                item.classList.add('visited');
            } else if (Number(item.dataset.step) == Number(this.current_step.dataset.step)) {
                item.classList.add('active');
                item.classList.remove('visited');
            } else {
                item.classList.remove('active');
                item.classList.remove('visited');
            }
        })
    }
}
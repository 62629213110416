import { Widgets } from "./Widgets";

export class Choices extends Widgets {
    constructor(selector) {
        super(selector);
        this.element.querySelectorAll(".card").forEach(elem=>{
            elem.addEventListener("click", e=>{
                let tgt = e.currentTarget;
                this.parent.loading.start();
                this.parent.pages.tutorial(
                    tgt.dataset.brandPath,
                    tgt.dataset.devicePath,
                    tgt.dataset.categoryPath,
                    tgt.dataset.tutorialPath,
                    tgt.dataset.choice
                );
            });
        });
    }
}
var Handlebars = require("../../../../CSR_QelpCare_Projects/csr-claro-br/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <li class=\"card "
    + alias4(((helper = (helper = lookupProperty(helpers,"path") || (depth0 != null ? lookupProperty(depth0,"path") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"path","hash":{},"data":data,"loc":{"start":{"line":10,"column":28},"end":{"line":10,"column":36}}}) : helper)))
    + "\">\n                <a href=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"root") || (depth0 != null ? lookupProperty(depth0,"root") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"root","hash":{},"data":data,"loc":{"start":{"line":11,"column":25},"end":{"line":11,"column":33}}}) : helper)))
    + alias4((lookupProperty(helpers,"route")||(depth0 && lookupProperty(depth0,"route"))||alias2).call(alias1,"application",{"name":"route","hash":{},"data":data,"loc":{"start":{"line":11,"column":33},"end":{"line":11,"column":56}}}))
    + "/"
    + alias4(((helper = (helper = lookupProperty(helpers,"path") || (depth0 != null ? lookupProperty(depth0,"path") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"path","hash":{},"data":data,"loc":{"start":{"line":11,"column":57},"end":{"line":11,"column":65}}}) : helper)))
    + "\" class=\"wrapper\" data-navigate-to=\"/"
    + alias4((lookupProperty(helpers,"route")||(depth0 && lookupProperty(depth0,"route"))||alias2).call(alias1,"application",{"name":"route","hash":{},"data":data,"loc":{"start":{"line":11,"column":102},"end":{"line":11,"column":125}}}))
    + "/"
    + alias4(((helper = (helper = lookupProperty(helpers,"path") || (depth0 != null ? lookupProperty(depth0,"path") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"path","hash":{},"data":data,"loc":{"start":{"line":11,"column":126},"end":{"line":11,"column":134}}}) : helper)))
    + "\">\n                    <p class=\"title\">\n                        <span class=\"app-name\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":13,"column":47},"end":{"line":13,"column":55}}}) : helper)))
    + "</span>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"os") : depth0),{"name":"each","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":24},"end":{"line":16,"column":33}}})) != null ? stack1 : "")
    + "                        <i class=\"material-icons\">chevron_right</i>\n                    </p>\n                </a>\n            </li>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <i class=\"icon os-icon "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"path") || (depth0 != null ? lookupProperty(depth0,"path") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"path","hash":{},"data":data,"loc":{"start":{"line":15,"column":51},"end":{"line":15,"column":59}}}) : helper)))
    + "\"></i>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<!-- Apps Template -->\n<div class=\"widget apps\">\n    <h2>"
    + alias3((lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"widget/apps/title",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":3,"column":8},"end":{"line":3,"column":41}}}))
    + "</h2>\n\n    <div class=\"content\">\n        <p class=\"description\">"
    + alias3((lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"widget/apps/description",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":6,"column":31},"end":{"line":6,"column":70}}}))
    + "</p>\n        <p><a href=\""
    + alias3(((helper = (helper = lookupProperty(helpers,"root") || (depth0 != null ? lookupProperty(depth0,"root") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"root","hash":{},"data":data,"loc":{"start":{"line":7,"column":20},"end":{"line":7,"column":28}}}) : helper)))
    + alias3((lookupProperty(helpers,"route")||(depth0 && lookupProperty(depth0,"route"))||alias2).call(alias1,"application",{"name":"route","hash":{},"data":data,"loc":{"start":{"line":7,"column":28},"end":{"line":7,"column":51}}}))
    + "\" class=\"see-all navigate-to\" data-navigate-to=\""
    + alias3((lookupProperty(helpers,"route")||(depth0 && lookupProperty(depth0,"route"))||alias2).call(alias1,"application",{"name":"route","hash":{},"data":data,"loc":{"start":{"line":7,"column":99},"end":{"line":7,"column":122}}}))
    + "\">"
    + alias3((lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"widget/apps/see-all",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":7,"column":124},"end":{"line":7,"column":159}}}))
    + " <i class=\"material-icons\">chevron_right</i> </a></p>\n        <ul class=\"panel\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"content") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":12},"end":{"line":21,"column":21}}})) != null ? stack1 : "")
    + "        </ul>\n    </div>\n</div>\n<!-- End Apps Template -->";
},"useData":true});
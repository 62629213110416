import { Widgets } from "./Widgets";

export class CopyURI extends Widgets {
    constructor(selector) {
        super(selector)
        this.textarea = document.createElement('textarea');
        this.textarea.style.position = "absolute";
        this.textarea.style.top = "0px";
        this.textarea.style.left = "0px";
        this.textarea.style.width = "0px";
        this.textarea.style.height = "0px";

        document.body.append(this.textarea);
        this.element.addEventListener("click", e => {
            this.textarea.focus();
            this.textarea.select();
            if (process.env.NODE_ENV === 'development') {
                console.log(e);
              }
          
            if (!navigator.clipboard){
                document.execCommand('copy');
                console.log("URL copied with execCommand");
            } else {
                navigator.clipboard.writeText(this.textarea.value).then(()=>{
                    console.log("URL copied with clipboard", this.textarea.value);
                });
            }
            this.element.classList.add("copied");
            setTimeout(()=>{
                this.element.classList.remove("copied");
            }, 2000)
        });
    }
    init() {
        let uri = this.element.dataset.href || this.parent.currentLocation(this.parent.router.root);
        let live = this.parent.settings.environment.live;
        let url = live + uri;
        this.textarea.value = url;
    }
}
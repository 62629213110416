var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"widget share-topic\">\n    <h3 class=\"widget title copy-link\">\n        <i class=\"material-icons-outlined\">file_copy</i>\n        "
    + alias3((lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"widget/share_topic/copy-link",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":4,"column":8},"end":{"line":4,"column":52}}}))
    + "\n    </h3>\n\n    <div class=\"widget tabs\">\n        <h3 class=\"tabs-title\">\n            Enviar tutorial\n        </h3>\n        <ul class=\"tabs-head\">\n            <li class=\"item-tab active sms-sender\" data-path=\"sms-sender\">\n                <i class=\"material-icons-outlined\">textsms</i>\n                SMS\n            </li>\n       </ul>\n        <ul class=\"tabs-content\">\n            <li class=\"content-tab sms-sender active\">\n                <div class=\"widget send-sms-form\">\n                    <div class=\"form-group\">\n                        <label for=\"to\">"
    + alias3((lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"widget/share_topic/phone",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":25,"column":40},"end":{"line":25,"column":80}}}))
    + "</label>\n                        <input class=\"form-field\" required type=\"text\" name=\"to\" placeholder=\"\" value=\"\" masked=\"1\">\n                        <label for=\"user\">"
    + alias3((lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"widget/share_topic/name",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":27,"column":42},"end":{"line":27,"column":81}}}))
    + "</label>\n                        <input class=\"form-field\" type=\"text\" name=\"user\" placeholder=\""
    + alias3((lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"widget/share_topic/name_placeholder",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":28,"column":87},"end":{"line":28,"column":139}}}))
    + "\" value=\"\">\n                        <label for=\"message\">"
    + alias3((lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"widget/share_topic/message",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":29,"column":45},"end":{"line":29,"column":87}}}))
    + "</label>\n                        <textarea class=\"form-field\" readonly name=\"message\"></textarea>\n                    </div>\n                    <button class=\"send-sms-button\">\n                        <i class=\"material-icons-outlined\">send</i>\n                        "
    + alias3((lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"widget/share_topic/send",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":34,"column":24},"end":{"line":34,"column":63}}}))
    + "\n                    </button>\n                </div>\n            </li>\n            <li class=\"content-tab whatsapp-interface\">\n                <div class=\"widget whatsapp-interface\">\n                    <div class=\"form-group\">\n                        <label for=\"to\">"
    + alias3((lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"widget/share_topic/whatsapp",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":41,"column":40},"end":{"line":41,"column":83}}}))
    + "</label>\n                        <input class=\"form-field\" required type=\"text\" name=\"to\" placeholder=\"\" value=>\n                        <label for=\"user\">"
    + alias3((lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"widget/share_topic/name",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":43,"column":42},"end":{"line":43,"column":81}}}))
    + "</label>\n                        <input class=\"form-field\" type=\"text\" required name=\"user\" placeholder=\""
    + alias3((lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"widget/share_topic/name_placeholder",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":44,"column":96},"end":{"line":44,"column":148}}}))
    + "\" value=\"\">\n                        <label for=\"message\">"
    + alias3((lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"widget/share_topic/message",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":45,"column":45},"end":{"line":45,"column":87}}}))
    + "</label>\n                        <textarea class=\"form-field\" readonly name=\"message\"></textarea>\n                    </div>\n                    <button class=\"send-sms-button send-button\">\n                        <i class=\"material-icons-outlined\">send</i>\n                        "
    + alias3((lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"widget/share_topic/send",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":50,"column":24},"end":{"line":50,"column":63}}}))
    + "\n                    </button>\n                </div>\n            </li>\n            <li class=\"content-tab send-email\">\n                <div class=\"widget send-email\">\n                    <div class=\"form-group\">\n                        <label for=\"to\">"
    + alias3((lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"widget/share_topic/email",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":57,"column":40},"end":{"line":57,"column":80}}}))
    + "</label>\n                        <input class=\"form-field\" required type=\"text\" name=\"to\" placeholder=\"\" value=\"\">\n                        <label for=\"user\">"
    + alias3((lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"widget/share_topic/name",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":59,"column":42},"end":{"line":59,"column":81}}}))
    + "</label>\n                        <input class=\"form-field\" type=\"text\" required name=\"user\" placeholder=\""
    + alias3((lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"widget/share_topic/name_placeholder",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":60,"column":96},"end":{"line":60,"column":148}}}))
    + "\" value=\"\">\n                        <label for=\"subject\">"
    + alias3((lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"widget/share_topic/subject",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":61,"column":45},"end":{"line":61,"column":87}}}))
    + "</label>\n                        <input class=\"form-field\" type=\"text\" required name=\"subject\" placeholder=\"Assunto do email\"\n                            value=\"\">\n                        <label for=\"message\">"
    + alias3((lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"widget/share_topic/message",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":64,"column":45},"end":{"line":64,"column":87}}}))
    + "</label>\n                        <textarea class=\"form-field\" required name=\"message\"></textarea>\n                    </div>\n                    <button class=\"send-sms-button send-button\">\n                        <i class=\"material-icons-outlined\">send</i>\n                        "
    + alias3((lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"widget/share_topic/send",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":69,"column":24},"end":{"line":69,"column":63}}}))
    + "\n                    </button>\n                </div>\n            </li>\n        </ul>\n    </div>\n</div>";
},"useData":true});
var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, alias4=container.lambda, alias5="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <li class=\"card\">\n\n            <div class=\"widget dropdown\">\n                <div class=\"dropdown-links\">\n                    <ul>\n                        <li>\n                            <a class=\"copy-url\" data-href=\""
    + alias3((lookupProperty(helpers,"route")||(depth0 && lookupProperty(depth0,"route"))||alias2).call(alias1,"devices",{"name":"route","hash":{},"data":data,"loc":{"start":{"line":13,"column":59},"end":{"line":13,"column":78}}}))
    + "/"
    + alias3(alias4(((stack1 = (depth0 != null ? lookupProperty(depth0,"device") : depth0)) != null ? lookupProperty(stack1,"path") : stack1), depth0))
    + "/"
    + alias3(((helper = (helper = lookupProperty(helpers,"topicPath") || (depth0 != null ? lookupProperty(depth0,"topicPath") : depth0)) != null ? helper : alias2),(typeof helper === alias5 ? helper.call(alias1,{"name":"topicPath","hash":{},"data":data,"loc":{"start":{"line":13,"column":95},"end":{"line":13,"column":108}}}) : helper)))
    + "\">\n                                <i class=\"material-icons-outlined\">insert_link</i>\n                                "
    + alias3((lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"widget/most_viewed_usecases/copy-link",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":15,"column":32},"end":{"line":15,"column":85}}}))
    + "\n                            </a>\n                        </li>\n                    </ul>\n                </div>\n            </div>\n\n            <a class=\"wrapper\" \n                    href=\""
    + alias3(((helper = (helper = lookupProperty(helpers,"root") || (depth0 != null ? lookupProperty(depth0,"root") : depth0)) != null ? helper : alias2),(typeof helper === alias5 ? helper.call(alias1,{"name":"root","hash":{},"data":data,"loc":{"start":{"line":23,"column":26},"end":{"line":23,"column":34}}}) : helper)))
    + alias3((lookupProperty(helpers,"route")||(depth0 && lookupProperty(depth0,"route"))||alias2).call(alias1,"oss",{"name":"route","hash":{},"data":data,"loc":{"start":{"line":23,"column":34},"end":{"line":23,"column":49}}}))
    + "/"
    + alias3(alias4(((stack1 = (depth0 != null ? lookupProperty(depth0,"software") : depth0)) != null ? lookupProperty(stack1,"path") : stack1), depth0))
    + "/"
    + alias3(((helper = (helper = lookupProperty(helpers,"topicPath") || (depth0 != null ? lookupProperty(depth0,"topicPath") : depth0)) != null ? helper : alias2),(typeof helper === alias5 ? helper.call(alias1,{"name":"topicPath","hash":{},"data":data,"loc":{"start":{"line":23,"column":68},"end":{"line":23,"column":81}}}) : helper)))
    + "\" \n                    data-navigate-to=\""
    + alias3((lookupProperty(helpers,"route")||(depth0 && lookupProperty(depth0,"route"))||alias2).call(alias1,"oss",{"name":"route","hash":{},"data":data,"loc":{"start":{"line":24,"column":38},"end":{"line":24,"column":53}}}))
    + "/"
    + alias3(alias4(((stack1 = (depth0 != null ? lookupProperty(depth0,"software") : depth0)) != null ? lookupProperty(stack1,"path") : stack1), depth0))
    + "/"
    + alias3(((helper = (helper = lookupProperty(helpers,"topicPath") || (depth0 != null ? lookupProperty(depth0,"topicPath") : depth0)) != null ? helper : alias2),(typeof helper === alias5 ? helper.call(alias1,{"name":"topicPath","hash":{},"data":data,"loc":{"start":{"line":24,"column":72},"end":{"line":24,"column":85}}}) : helper)))
    + "\" \n            >\n                <div class=\"details\">\n                    "
    + alias3(((helper = (helper = lookupProperty(helpers,"topicName") || (depth0 != null ? lookupProperty(depth0,"topicName") : depth0)) != null ? helper : alias2),(typeof helper === alias5 ? helper.call(alias1,{"name":"topicName","hash":{},"data":data,"loc":{"start":{"line":27,"column":20},"end":{"line":27,"column":33}}}) : helper)))
    + "\n                    <div class=\"tech\">\n                        <div class=\"device\"><span>"
    + alias3(alias4(((stack1 = (depth0 != null ? lookupProperty(depth0,"software") : depth0)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "</span></div> \n                        <div class=\"manufacturer\"><span>"
    + alias3(alias4(((stack1 = (depth0 != null ? lookupProperty(depth0,"os") : depth0)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "</span></div>\n                    </div>\n                </div>\n                <div class=\"icon-holder\">\n                    <i class=\"material-icons\">chevron_right</i>\n                </div>\n            </a>\n        </li>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"pagination\" data-per-page=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"perpage") || (depth0 != null ? lookupProperty(depth0,"perpage") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"perpage","hash":{},"data":data,"loc":{"start":{"line":41,"column":47},"end":{"line":41,"column":58}}}) : helper)))
    + "\">\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "        <div class=\"pagination\" data-per-page=\"2\">\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n<!-- Most Viewed Usecases Template -->\n<div class=\"widget most_viewed_usecases most_viewed_usecases_oss\">\n    <h2 class=\"title\">"
    + container.escapeExpression((lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||container.hooks.helperMissing).call(alias1,"widget/most_viewed_usecases/title",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":4,"column":22},"end":{"line":4,"column":71}}}))
    + "</h2>\n    <ul class=\"panel\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"content") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":8},"end":{"line":38,"column":17}}})) != null ? stack1 : "")
    + "    </ul>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"perpage") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(5, data, 0),"data":data,"loc":{"start":{"line":40,"column":4},"end":{"line":44,"column":11}}})) != null ? stack1 : "")
    + "        <div class=\"pagination-left\">\n            <i class=\"material-icons\">chevron_left</i>\n        </div>\n        <div class=\"pagination-right\">\n            <i class=\"material-icons\">chevron_right</i>\n        </div>\n    </div>\n</div>\n<!-- End Most Viewed Usecases Template -->";
},"useData":true});
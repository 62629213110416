import { Widgets } from "./Widgets";

export class ULPaginated extends Widgets {
    constructor(selector) {
        super(selector);
        this.simpleNavigate();
        this.list = (this.element) ? this.element.querySelectorAll("ul li") : false;
        this.page = 0;
        this.itemPerPage;
        this.next_page;
        this.prev_page;

        if(!this.list){
            return;
        }

        this.list.forEach(li => {

        });

        this.controls = this.element.querySelector(".pagination");

        if (!this.controls) {
            console.warn("Missing Pagination CSS Class");
        } else {
            this.itemPerPage = Number(this.controls.dataset.perPage);
            this.next_page = this.controls.querySelector(".pagination-right");
            this.prev_page = this.controls.querySelector(".pagination-left");
            this.next_page.addEventListener("click", e => {
                let pages = this.list.length / this.itemPerPage;
                if (this.page + 1 < pages) {
                    this.gotoPage(this.page + 1);
                }
            });
            this.prev_page.addEventListener("click", e => {
                let pages = this.list.length / this.itemPerPage;
                if (this.page - 1 >= 0) {
                    this.gotoPage(this.page - 1);
                }
            });

            this.gotoPage(0);
        }
    }
    gotoPage(number) {
        this.page = number;
        let pageInit = this.page * this.itemPerPage;
        let pageEnd = (this.page + 1) * this.itemPerPage;
        for (let i = 0; i < this.list.length; i++) {
            if (i >= pageInit && i < pageEnd) {
                this.list[i].classList.add("active");
            } else {
                this.list[i].classList.remove("active");
            }
        }
    }
}
var Handlebars = require("../../../../CSR_QelpCare_Projects/csr-claro-br/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <li class=\"card "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"os") : depth0)) != null ? lookupProperty(stack1,"path") : stack1), depth0))
    + "\" data-path=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"os") : depth0)) != null ? lookupProperty(stack1,"path") : stack1), depth0))
    + "\">\n                <a class=\"wrapper\">\n                    <div class=\"details\">\n                        <label class=\"title\">\n                            "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"os") : depth0)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "\n                        </label>\n                    </div>\n                </a>\n            </li>\n";
},"3":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"type") : depth0),{"name":"each","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":23,"column":16},"end":{"line":47,"column":25}}})) != null ? stack1 : "");
},"4":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"ifEquals")||(depth0 && lookupProperty(depth0,"ifEquals"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"path") : depth0),"smartphone",{"name":"ifEquals","hash":{},"fn":container.program(5, data, 0, blockParams, depths),"inverse":container.program(7, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":24,"column":20},"end":{"line":46,"column":33}}})) != null ? stack1 : "");
},"5":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), alias4=container.hooks.helperMissing, alias5="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <li class=\"card device "
    + alias2(alias1((depths[1] != null ? lookupProperty(depths[1],"os") : depths[1]), depth0))
    + " type-smartphone\">\n                            <a class=\"wrapper\" href=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"root") || (depth0 != null ? lookupProperty(depth0,"root") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"root","hash":{},"data":data,"loc":{"start":{"line":26,"column":53},"end":{"line":26,"column":61}}}) : helper)))
    + alias2((lookupProperty(helpers,"route")||(depth0 && lookupProperty(depth0,"route"))||alias4).call(alias3,"oss",{"name":"route","hash":{},"data":data,"loc":{"start":{"line":26,"column":61},"end":{"line":26,"column":76}}}))
    + "/"
    + alias2(alias1((depths[1] != null ? lookupProperty(depths[1],"os") : depths[1]), depth0))
    + "/"
    + alias2(alias1((depths[2] != null ? lookupProperty(depths[2],"topic") : depths[2]), depth0))
    + "\" data-navigate-to=\""
    + alias2((lookupProperty(helpers,"route")||(depth0 && lookupProperty(depth0,"route"))||alias4).call(alias3,"oss",{"name":"route","hash":{},"data":data,"loc":{"start":{"line":26,"column":122},"end":{"line":26,"column":137}}}))
    + "/"
    + alias2(alias1((depths[1] != null ? lookupProperty(depths[1],"os") : depths[1]), depth0))
    + "/"
    + alias2(alias1((depths[2] != null ? lookupProperty(depths[2],"topic") : depths[2]), depth0))
    + "\">\n                                <div class=\"image\">\n                                    <img src=\""
    + alias2(alias1(((stack1 = (depths[1] != null ? lookupProperty(depths[1],"softwareImage") : depths[1])) != null ? lookupProperty(stack1,"imageURL") : stack1), depth0))
    + "\" alt=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":28,"column":82},"end":{"line":28,"column":90}}}) : helper)))
    + "\">\n                                </div>\n                                <div class=\"details\">\n                                    <h3 class=\"title\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":31,"column":54},"end":{"line":31,"column":62}}}) : helper)))
    + "</h3>\n                                </div>\n                            </a>\n                        </li>\n";
},"7":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), alias4=container.hooks.helperMissing, alias5="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <li class=\"card device "
    + alias2(alias1((depths[1] != null ? lookupProperty(depths[1],"os") : depths[1]), depth0))
    + "\">\n                            <a class=\"wrapper\" href=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"root") || (depth0 != null ? lookupProperty(depth0,"root") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"root","hash":{},"data":data,"loc":{"start":{"line":37,"column":53},"end":{"line":37,"column":61}}}) : helper)))
    + alias2((lookupProperty(helpers,"route")||(depth0 && lookupProperty(depth0,"route"))||alias4).call(alias3,"application",{"name":"route","hash":{},"data":data,"loc":{"start":{"line":37,"column":61},"end":{"line":37,"column":84}}}))
    + "/"
    + alias2(alias1((depths[1] != null ? lookupProperty(depths[1],"path") : depths[1]), depth0))
    + "/"
    + alias2(alias1((depths[1] != null ? lookupProperty(depths[1],"os") : depths[1]), depth0))
    + "/"
    + alias2(alias1((depths[2] != null ? lookupProperty(depths[2],"topic") : depths[2]), depth0))
    + "\" data-navigate-to=\""
    + alias2((lookupProperty(helpers,"route")||(depth0 && lookupProperty(depth0,"route"))||alias4).call(alias3,"application",{"name":"route","hash":{},"data":data,"loc":{"start":{"line":37,"column":142},"end":{"line":37,"column":165}}}))
    + "/"
    + alias2(alias1((depths[1] != null ? lookupProperty(depths[1],"path") : depths[1]), depth0))
    + "/"
    + alias2(alias1((depths[1] != null ? lookupProperty(depths[1],"os") : depths[1]), depth0))
    + "/"
    + alias2(alias1((depths[2] != null ? lookupProperty(depths[2],"topic") : depths[2]), depth0))
    + "\">\n                                <div class=\"image\">\n                                    <img src=\""
    + alias2(alias1(((stack1 = (depths[1] != null ? lookupProperty(depths[1],"softwareImage") : depths[1])) != null ? lookupProperty(stack1,"imageURL") : stack1), depth0))
    + "\" alt=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":39,"column":82},"end":{"line":39,"column":90}}}) : helper)))
    + "\">\n                                </div>\n                                <div class=\"details\">\n                                    <h3 class=\"title\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":42,"column":54},"end":{"line":42,"column":62}}}) : helper)))
    + "</h3>\n                                </div>\n                            </a>\n                        </li>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<!-- Widget Software by OS -->\n<div class=\"widget devices-by-brand software-by-os dts-item-by-filter\">\n    <h2 class=\"title\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":3,"column":22},"end":{"line":3,"column":31}}}) : helper)))
    + "</h2>\n    <p class=\"description\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"description") || (depth0 != null ? lookupProperty(depth0,"description") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"description","hash":{},"data":data,"loc":{"start":{"line":4,"column":27},"end":{"line":4,"column":42}}}) : helper)))
    + "</p>\n    <div class=\"filter widget os-list filter-list\">\n        <ul class=\"panel\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"oss") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":12},"end":{"line":17,"column":21}}})) != null ? stack1 : "")
    + "        </ul>\n    </div>\n    <div class=\"devices widget software-list item-list\">\n        <ul class=\"panel\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"softwares") : depth0),{"name":"each","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":22,"column":12},"end":{"line":48,"column":21}}})) != null ? stack1 : "")
    + "        </ul>\n    </div>\n</div>\n<!-- End Widget Software by OS -->";
},"useData":true,"useDepths":true});
import { Widgets } from "./Widgets";

export class Specs extends Widgets {
  constructor(selector) {
    super(selector);
    this.button = document.querySelector(".simple-button.specs");
    this.close_btn = this.element
      ? this.element.querySelector(".close-btn")
      : false;


    this.scroll_to_top = this.element.querySelector(".scroll-to-top");
    this.scroll_to_top?.addEventListener("click", (e) => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    });
    this.button.addEventListener("click", (e) => {
      console.log(e);
      this.toggle(e);
    });
    if (!this.close_btn) {
      return;
    }
    this.close_btn.addEventListener("click", (e) => {
      console.log(e);
      this.toggle(e);
    });
  }
  toggle(e) {
    e?.preventDefault();
    if (this.element.classList.contains("active")) {
      this.button.classList.remove("active");
      this.element.classList.remove("active");
      if (window.innerWidth > this.getCSSVariableNumber("--max-width")) {
        document.body.classList.remove("frozen");
      }
    } else {
      window.scrollTo(0, 0);
      this.button.classList.add("active");
      this.element.classList.add("active");
      if (window.innerWidth > this.getCSSVariableNumber("--max-width")) {
        document.body.classList.add("frozen");
      }
    }
  }
}

import { Accordion } from "./Accordion";

export class SideIndex extends Accordion {
  constructor(selector) {
    super(selector);
    this.scroll_element = window;

    if (this.element && this.element.querySelectorAll("li a")) {
      this.element.querySelectorAll("li a").forEach((elem) => {
        elem.addEventListener("click", (e) => {
          e.preventDefault();
          let target = document.querySelector(
            `.widget.${e.currentTarget.dataset.widget} h2`
          );
          console.log(target);
          setTimeout(()=>{
            target.classList.remove("target");
            target.classList.add("targeted");
          }, 400)
          setTimeout(()=>{
            target.classList.remove("targeted");
          }, 1400)
          target.classList.add("target");
          target.scrollIntoView({
            behavior: "smooth",
            block: "center"
          })
        });
      });
    }

    if (this.accordion_list?.length > 0) {
      window.addEventListener("resize", (e) => {
        this.checkScrenSize();
      });
      this.checkScrenSize();
    }
  }
  checkScrenSize() {
    if (!this.element || !this.element.querySelector("h1.title")) {
      return;
    }
    if (window.innerWidth > this.getCSSVariableNumber("--max-width")) {
      this.open(this.element.querySelector("h1.title"));
    } else {
      this.close(this.element.querySelector("h1.title"));
    }
  }
}

var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"with").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"specs") : depth0),{"name":"with","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":8},"end":{"line":40,"column":17}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"widget specs here-page-device\">\n            <div class=\"widget side-menu\">\n                <h2 class=\"title\">"
    + container.escapeExpression((lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||container.hooks.helperMissing).call(alias1,"page/device/specs_title",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":7,"column":34},"end":{"line":7,"column":73}}}))
    + "</h2>\n                <ul class=\"panel\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"specifications") : depth0)) != null ? lookupProperty(stack1,"properties") : stack1),{"name":"each","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":20},"end":{"line":13,"column":29}}})) != null ? stack1 : "")
    + "                </ul>\n            </div>\n            <div class=\"content\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"specifications") : depth0)) != null ? lookupProperty(stack1,"properties") : stack1),{"name":"each","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":17,"column":16},"end":{"line":37,"column":25}}})) != null ? stack1 : "")
    + "            </div>\n        </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <li class=\"specifications card "
    + alias4(((helper = (helper = lookupProperty(helpers,"path") || (depth0 != null ? lookupProperty(depth0,"path") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"path","hash":{},"data":data,"loc":{"start":{"line":10,"column":51},"end":{"line":10,"column":59}}}) : helper)))
    + "\">\n                        <a href=\"#"
    + alias4(((helper = (helper = lookupProperty(helpers,"path") || (depth0 != null ? lookupProperty(depth0,"path") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"path","hash":{},"data":data,"loc":{"start":{"line":11,"column":34},"end":{"line":11,"column":42}}}) : helper)))
    + "\" data-widget=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"path") || (depth0 != null ? lookupProperty(depth0,"path") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"path","hash":{},"data":data,"loc":{"start":{"line":11,"column":57},"end":{"line":11,"column":65}}}) : helper)))
    + "\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":11,"column":67},"end":{"line":11,"column":75}}}) : helper)))
    + "</a>\n                    </li>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"specifications widget "
    + alias4(((helper = (helper = lookupProperty(helpers,"path") || (depth0 != null ? lookupProperty(depth0,"path") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"path","hash":{},"data":data,"loc":{"start":{"line":18,"column":50},"end":{"line":18,"column":58}}}) : helper)))
    + "\">\n                    <h2 class=\"spec-title\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":19,"column":43},"end":{"line":19,"column":51}}}) : helper)))
    + "</h2>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"settings") : depth0),{"name":"each","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":20,"column":20},"end":{"line":35,"column":29}}})) != null ? stack1 : "")
    + "                </div>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <p>\n                        <span class=\"name\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":22,"column":43},"end":{"line":22,"column":51}}}) : helper)))
    + "</span><br>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"boolean") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(12, data, 0),"data":data,"loc":{"start":{"line":23,"column":24},"end":{"line":33,"column":31}}})) != null ? stack1 : "")
    + "                    </p>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <span class=\"value "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"value") || (depth0 != null ? lookupProperty(depth0,"value") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"value","hash":{},"data":data,"loc":{"start":{"line":24,"column":43},"end":{"line":24,"column":52}}}) : helper)))
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"value") : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.program(10, data, 0),"data":data,"loc":{"start":{"line":25,"column":28},"end":{"line":29,"column":35}}})) != null ? stack1 : "")
    + "                        </span><br>\n";
},"8":function(container,depth0,helpers,partials,data) {
    return "                            <i class=\"material-icons true\">check</i>\n";
},"10":function(container,depth0,helpers,partials,data) {
    return "                            <i class=\"material-icons false\">close</i>\n";
},"12":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <span class=\"value\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"value") || (depth0 != null ? lookupProperty(depth0,"value") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"value","hash":{},"data":data,"loc":{"start":{"line":32,"column":44},"end":{"line":32,"column":53}}}) : helper)))
    + "</span><br>\n";
},"14":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <li class=\"item-tab tabs-title "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"device") : depth0)) != null ? lookupProperty(stack1,"path") : stack1), depth0))
    + "\" data-path=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"device") : depth0)) != null ? lookupProperty(stack1,"path") : stack1), depth0))
    + "\">\n                    "
    + ((stack1 = alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"device") : depth0)) != null ? lookupProperty(stack1,"name") : stack1), depth0)) != null ? stack1 : "")
    + "\n                </li>\n";
},"16":function(container,depth0,helpers,partials,data,blockParams) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <li class=\"item-tab "
    + alias2(alias1(((stack1 = blockParams[0][0]) != null ? lookupProperty(stack1,"path") : stack1), depth0))
    + "\" data-path=\""
    + alias2(alias1(((stack1 = blockParams[0][0]) != null ? lookupProperty(stack1,"path") : stack1), depth0))
    + "\">\n                    <span class=\"item-name\">"
    + alias2(alias1(((stack1 = blockParams[0][0]) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "</span>\n                </li>\n";
},"18":function(container,depth0,helpers,partials,data) {
    var stack1, helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <li class=\"content-tab tab-0 "
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"device") : depth0)) != null ? lookupProperty(stack1,"path") : stack1), depth0))
    + " active\">\n                        "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"categories_tutorials") || (depth0 != null ? lookupProperty(depth0,"categories_tutorials") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"categories_tutorials","hash":{},"data":data,"loc":{"start":{"line":65,"column":24},"end":{"line":65,"column":50}}}) : helper))) != null ? stack1 : "")
    + "\n                    </li>\n";
},"20":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <li class=\"content-tab tab-"
    + alias3(((helper = (helper = lookupProperty(helpers,"key") || (data && lookupProperty(data,"key"))) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"key","hash":{},"data":data,"loc":{"start":{"line":70,"column":47},"end":{"line":70,"column":55}}}) : helper)))
    + " "
    + alias3(container.lambda((depth0 != null ? lookupProperty(depth0,"path") : depth0), depth0))
    + " active\">\n                        "
    + ((stack1 = (lookupProperty(helpers,"getHTML")||(depth0 && lookupProperty(depth0,"getHTML"))||alias2).call(alias1,(depths[1] != null ? lookupProperty(depths[1],"topic_by_os") : depths[1]),(depth0 != null ? lookupProperty(depth0,"path") : depth0),{"name":"getHTML","hash":{},"data":data,"loc":{"start":{"line":71,"column":24},"end":{"line":71,"column":62}}})) != null ? stack1 : "")
    + "\n                    </li>\n";
},"22":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <!-- Wrapped Start Screen and Device Image-->\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depths[1] != null ? lookupProperty(depths[1],"iswrapped") : depths[1]),{"name":"if","hash":{},"fn":container.program(23, data, 0, blockParams, depths),"inverse":container.program(25, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":84,"column":20},"end":{"line":102,"column":27}}})) != null ? stack1 : "")
    + "                    <!-- Wrapped Start Screen and Device Image-->\n";
},"23":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), alias4=container.hooks.helperMissing, alias5="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <div class=\"image\" data-screen-size-x=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"deviceImage") : depth0)) != null ? lookupProperty(stack1,"screenSize") : stack1)) != null ? lookupProperty(stack1,"x") : stack1), depth0))
    + "\"\n                        data-screen-size-y=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"deviceImage") : depth0)) != null ? lookupProperty(stack1,"screenSize") : stack1)) != null ? lookupProperty(stack1,"y") : stack1), depth0))
    + "\"\n                        data-image-size-x=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"deviceImage") : depth0)) != null ? lookupProperty(stack1,"imageSize") : stack1)) != null ? lookupProperty(stack1,"x") : stack1), depth0))
    + "\" data-image-size-y=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"deviceImage") : depth0)) != null ? lookupProperty(stack1,"imageSize") : stack1)) != null ? lookupProperty(stack1,"y") : stack1), depth0))
    + "\"\n                        data-screen-position-x=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"deviceImage") : depth0)) != null ? lookupProperty(stack1,"screenPosition") : stack1)) != null ? lookupProperty(stack1,"x") : stack1), depth0))
    + "\"\n                        data-screen-position-y=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"deviceImage") : depth0)) != null ? lookupProperty(stack1,"screenPosition") : stack1)) != null ? lookupProperty(stack1,"y") : stack1), depth0))
    + "\"\n                        data-offset-x=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"deviceImage") : depth0)) != null ? lookupProperty(stack1,"offset") : stack1)) != null ? lookupProperty(stack1,"x") : stack1), depth0))
    + "\" data-offset-y=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"deviceImage") : depth0)) != null ? lookupProperty(stack1,"offset") : stack1)) != null ? lookupProperty(stack1,"y") : stack1), depth0))
    + "\">\n                        <div class=\"image-wrapper\">\n                            <img class=\"hardware\" src=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"deviceImage") : depth0)) != null ? lookupProperty(stack1,"imageURL") : stack1), depth0))
    + "\" alt=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":92,"column":86},"end":{"line":92,"column":94}}}) : helper)))
    + "\">\n                            <img class=\"startScreen\" src=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"startScreenImageURL") || (depth0 != null ? lookupProperty(depth0,"startScreenImageURL") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"startScreenImageURL","hash":{},"data":data,"loc":{"start":{"line":93,"column":58},"end":{"line":93,"column":81}}}) : helper)))
    + "\" alt=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":93,"column":88},"end":{"line":93,"column":96}}}) : helper)))
    + "\">\n                        </div>\n                    </div>\n";
},"25":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <div class=\"image\">\n                        <div class=\"image-wrapper\">\n                            <img class=\"startScreen\" src=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"startScreenImageURL") || (depth0 != null ? lookupProperty(depth0,"startScreenImageURL") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"startScreenImageURL","hash":{},"data":data,"loc":{"start":{"line":99,"column":58},"end":{"line":99,"column":81}}}) : helper)))
    + "\" alt=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":99,"column":88},"end":{"line":99,"column":96}}}) : helper)))
    + "\">\n                        </div>\n                    </div>\n";
},"27":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"simple-button specs\">\n                    <p>\n                        <i class=\"material-icons show\">chevron_left</i>\n                        <i class=\"material-icons hide\">close</i> "
    + container.escapeExpression((lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"page/device/specs",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":114,"column":65},"end":{"line":114,"column":98}}}))
    + "\n                    </p>\n                </div>\n";
},"29":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"simple-button borded manual\">\n                    <p>\n                        <a href=\""
    + alias3(((helper = (helper = lookupProperty(helpers,"manual_link") || (depth0 != null ? lookupProperty(depth0,"manual_link") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"manual_link","hash":{},"data":data,"loc":{"start":{"line":121,"column":33},"end":{"line":121,"column":48}}}) : helper)))
    + "\" target=\"_blank\">\n                            <i class=\"material-icons-outlined\">description</i>  "
    + alias3((lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||alias2).call(alias1,"page/device/manual",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":122,"column":80},"end":{"line":122,"column":114}}}))
    + "\n                        </a>\n                    </p>\n                </div>\n";
},"31":function(container,depth0,helpers,partials,data) {
    var stack1, helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <div class=\"mostviewed-tabs "
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"device") : depth0)) != null ? lookupProperty(stack1,"path") : stack1), depth0))
    + " active\">\n                        "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"most_viewed_usecases") || (depth0 != null ? lookupProperty(depth0,"most_viewed_usecases") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"most_viewed_usecases","hash":{},"data":data,"loc":{"start":{"line":131,"column":24},"end":{"line":131,"column":50}}}) : helper))) != null ? stack1 : "")
    + "\n                    </div>\n";
},"33":function(container,depth0,helpers,partials,data,blockParams) {
    var stack1, alias1=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <div class=\"mostviewed-tabs "
    + container.escapeExpression(alias1(blockParams[0][1], depth0))
    + " "
    + ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"top_usecases") : depth0),{"name":"unless","hash":{},"fn":container.program(34, data, 0, blockParams),"inverse":container.noop,"data":data,"blockParams":blockParams,"loc":{"start":{"line":135,"column":56},"end":{"line":135,"column":97}}})) != null ? stack1 : "")
    + "\">\n                        "
    + ((stack1 = alias1(blockParams[0][0], depth0)) != null ? stack1 : "")
    + "\n                    </div>\n";
},"34":function(container,depth0,helpers,partials,data) {
    return "active";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<!-- Device Template Custom-->\n<div class=\"page device\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"specs") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"blockParams":blockParams,"loc":{"start":{"line":3,"column":4},"end":{"line":41,"column":11}}})) != null ? stack1 : "")
    + "    <div class=\"wrapper\">\n        "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"breadcrumbs") || (depth0 != null ? lookupProperty(depth0,"breadcrumbs") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"breadcrumbs","hash":{},"data":data,"blockParams":blockParams,"loc":{"start":{"line":43,"column":8},"end":{"line":43,"column":25}}}) : helper))) != null ? stack1 : "")
    + "\n        <h1 class=\"page-title\">"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"device") : depth0)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "</h1>\n\n\n        <div class=\"widget tabs\">\n            <ul class=\"tabs-head\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"categories") : depth0),{"name":"if","hash":{},"fn":container.program(14, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"blockParams":blockParams,"loc":{"start":{"line":49,"column":16},"end":{"line":53,"column":23}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"softwares") : depth0),{"name":"each","hash":{},"fn":container.program(16, data, 1, blockParams, depths),"inverse":container.noop,"data":data,"blockParams":blockParams,"loc":{"start":{"line":55,"column":16},"end":{"line":59,"column":25}}})) != null ? stack1 : "")
    + "\n            </ul>\n            <ul class=\"tabs-content\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"categories") : depth0),{"name":"if","hash":{},"fn":container.program(18, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"blockParams":blockParams,"loc":{"start":{"line":63,"column":16},"end":{"line":67,"column":23}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"softwares") : depth0),{"name":"each","hash":{},"fn":container.program(20, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"blockParams":blockParams,"loc":{"start":{"line":69,"column":16},"end":{"line":73,"column":25}}})) != null ? stack1 : "")
    + "            </ul>\n        </div>\n    </div>\n    <div class=\"aside-content\">\n        <div class=\"device-details\">\n            <div class=\"device-images\">\n                <div class=\"widget active-device-image\">\n\n"
    + ((stack1 = lookupProperty(helpers,"with").call(alias1,(depth0 != null ? lookupProperty(depth0,"device") : depth0),{"name":"with","hash":{},"fn":container.program(22, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"blockParams":blockParams,"loc":{"start":{"line":82,"column":20},"end":{"line":104,"column":29}}})) != null ? stack1 : "")
    + "                </div>\n\n\n            </div>\n            <div class=\"buttons\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"specs") : depth0),{"name":"if","hash":{},"fn":container.program(27, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"blockParams":blockParams,"loc":{"start":{"line":110,"column":16},"end":{"line":117,"column":23}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"manual_link") : depth0),{"name":"if","hash":{},"fn":container.program(29, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"blockParams":blockParams,"loc":{"start":{"line":118,"column":16},"end":{"line":126,"column":23}}})) != null ? stack1 : "")
    + "            </div>\n            <div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"top_usecases") : depth0),{"name":"if","hash":{},"fn":container.program(31, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"blockParams":blockParams,"loc":{"start":{"line":129,"column":16},"end":{"line":133,"column":23}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"popular_by_os") : depth0),{"name":"each","hash":{},"fn":container.program(33, data, 2, blockParams, depths),"inverse":container.noop,"data":data,"blockParams":blockParams,"loc":{"start":{"line":134,"column":16},"end":{"line":138,"column":25}}})) != null ? stack1 : "")
    + "            </div>\n        </div>\n    </div>\n</div>\n<!-- End Device Template -->";
},"useData":true,"useDepths":true,"useBlockParams":true});
var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"type") : depth0),{"name":"each","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":8},"end":{"line":26,"column":17}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"ifEquals")||(depth0 && lookupProperty(depth0,"ifEquals"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"path") : depth0),"smartphone",{"name":"ifEquals","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":10},"end":{"line":25,"column":23}}})) != null ? stack1 : "");
},"3":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, alias4=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <li class=\"card\">\n              <a\n                href=\""
    + alias3(((helper = (helper = lookupProperty(helpers,"root") || (depth0 != null ? lookupProperty(depth0,"root") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"root","hash":{},"data":data,"loc":{"start":{"line":10,"column":22},"end":{"line":10,"column":30}}}) : helper)))
    + alias3((lookupProperty(helpers,"route")||(depth0 && lookupProperty(depth0,"route"))||alias2).call(alias1,"oss",{"name":"route","hash":{},"data":data,"loc":{"start":{"line":10,"column":30},"end":{"line":10,"column":45}}}))
    + "/"
    + alias3(alias4((depths[1] != null ? lookupProperty(depths[1],"path") : depths[1]), depth0))
    + "\"\n                class=\"wrapper\"\n                data-navigate-to=\""
    + alias3((lookupProperty(helpers,"route")||(depth0 && lookupProperty(depth0,"route"))||alias2).call(alias1,"oss",{"name":"route","hash":{},"data":data,"loc":{"start":{"line":12,"column":34},"end":{"line":12,"column":49}}}))
    + "/"
    + alias3(alias4((depths[1] != null ? lookupProperty(depths[1],"path") : depths[1]), depth0))
    + "\"\n              >\n\n                <img class=\"mdn-Icon\" src=\""
    + alias3(alias4(((stack1 = (depths[1] != null ? lookupProperty(depths[1],"softwareImage") : depths[1])) != null ? lookupProperty(stack1,"imageURL") : stack1), depth0))
    + "\" />\n\n                <p class=\"title\">\n                  "
    + alias3(alias4((depths[1] != null ? lookupProperty(depths[1],"name") : depths[1]), depth0))
    + "\n                </p>\n                <div class=\"icon-holder\">\n                  <i class=\"material-icons\">chevron_right</i>\n                </div>\n              </a>\n            </li>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"widget sistemas_operacionais\">\n  <h2>"
    + container.escapeExpression((lookupProperty(helpers,"translate")||(depth0 && lookupProperty(depth0,"translate"))||container.hooks.helperMissing).call(alias1,"widget/sistemas_operacionais/title",{"name":"translate","hash":{},"data":data,"loc":{"start":{"line":2,"column":6},"end":{"line":2,"column":56}}}))
    + "</h2>\n\n  <ul class=\"panel\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"content") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":4},"end":{"line":27,"column":13}}})) != null ? stack1 : "")
    + "  </ul>\n</div>";
},"useData":true,"useDepths":true});